import React, { useContext } from 'react';
import { useTokenUserSecret } from '../libs/tokenUserAuth';
import { useRouteMatch } from 'react-router-dom';
import { IonBadge, IonItem, IonLabel, IonList, IonMenuToggle } from '@ionic/react';
import {
  BusinessNavIcon,
  CustomersNavIcon, DirectMessagesNavIcon,
  MessagesNavIcon, PostsNavIcon,
  SettingsNavIcon
} from '../libs/icons';
import { AlertsContext } from './Alerts';

export function BusinessMenuItem ({ name, path }: { name: string, path: string }) {
  const { pathToAlertIds } = useContext(AlertsContext);

  return (<>
    <MenuItem routerLink={`${path}/dashboard`} icon={BusinessNavIcon} title={name} />
    <IonItem color='dark'>
      <IonList className='py-0 w-100'>
        <MenuItem routerLink={`${path}/posts`} icon={PostsNavIcon}
                  title={'Posts'} alertCount={pathToAlertIds.get(`${path}/posts`)?.length}/>
        <MenuItem routerLink={`${path}/messages`} icon={DirectMessagesNavIcon}
                  title={'Direct Messages'} alertCount={pathToAlertIds.get(`${path}/messages`)?.length}/>
        <MenuItem routerLink={`${path}/customers`} icon={CustomersNavIcon}
                  title={'Customers'} alertCount={pathToAlertIds.get(`${path}/customers`)?.length}/>
        <MenuItem routerLink={`${path}/settings`} icon={SettingsNavIcon}
                  title={'Settings'}/>
      </IonList>
    </IonItem>
  </>);
}

export function ConnectionMenuItem ({ connectionPerms }: { connectionPerms: { name: string, path: string }[] }) {
  const { pathToAlertIds } = useContext(AlertsContext);

  if (connectionPerms.length === 0) {
    return (<></>);
  }

  return (<>
    { connectionPerms.map(({ name, path }) =>
      <MenuItem
        key={path}
        routerLink={`${path}/messages`}
        icon={MessagesNavIcon}
        alertCount={pathToAlertIds.get(`${path}/messages`)?.length}
        title={name} />
    )}
  </>);
}

export function MenuItem ({ routerLink, icon: MenuIcon, title, alertCount }:
  { routerLink?: string, icon?: React.ComponentType<{ className: string }>, title: string, alertCount?: number }) {
  const { tusifyUrl } = useTokenUserSecret();
  const active = !!useRouteMatch({ path: routerLink, exact: true });
  return (
    <IonMenuToggle autoHide={false}>
      <IonItem color='dark' routerLink={routerLink && tusifyUrl(routerLink)} detail={!!routerLink}>
        { MenuIcon &&
          <div slot='start' className='text-white-50'>
            <MenuIcon className={active ? 'active-menu-icon' : ''}/>
          </div>
        }
        <IonLabel>{title}</IonLabel>
        {!!(alertCount && alertCount > 0) && <IonBadge color="danger" slot='end'>{alertCount}</IonBadge>}
      </IonItem>
    </IonMenuToggle>
  );
}
