import React from 'react';
import { Form, Formik, FormikHelpers, useField, useFormikContext } from 'formik';
import { schemas } from 'sprancer-shared';

import {
  IonItem,
  IonRow,
  IonCol,
  IonLabel, IonGrid, IonList, IonInput, IonNote, CreateAnimation, isPlatform
} from '@ionic/react';
import { IonFormikInput, UnexpectedFormErrors } from '../../components/Forms';
import { useFetcher } from 'rest-hooks';
import { NoAuthLayoutPage } from '../../containers/NoAuthLayout';
import { useHistory } from 'react-router-dom';
import { reportException } from '../../libs/errors';
import { SendLoginTokenResource, UserResource } from '../../models/user';
import { TextSaveButton } from '../../components/FormButtons';
import { useBackground } from '../../libs/background';
import * as yup from 'yup';
import { ActivationBanner, ActivationWelcome } from '../../components/Activations';
import { EmailFormLabelIcon, NameFormLabelIcon } from '../../libs/icons';
import { ActivationResource, genSavedActivationId } from '../../models/activation';

export default function Welcome () {
  const { contentStyle } = useBackground();

  return (
    <NoAuthLayoutPage
      header={<Header/>}
      content={<Content/>}
      contentStyle={contentStyle}
    />
  );
}

function Header () {
  return (
    <></>
  );
}

const WelcomeFormSchema = yup.object({
  formState: yup.string().required(),
  email: yup.string().email('must be a valid email').required('required'),
  nickname: yup.string().when('formState', {
    is: 'createaccount',
    then: yup.string().required('required'),
    otherwise: yup.string().notRequired()
  })
});

export type WelcomeFormType = {
  formState: 'default' | 'createaccount' | 'tokensent',
  email: string;
  nickname?: string;
}

function Content () {
  const history = useHistory<{ email?: string, formState?: WelcomeFormType['formState'], activationId?: string, userName?: string }>();
  const activationId = history.location.state?.activationId;

  const initialValues: WelcomeFormType = {
    email: history.location.state?.email || '',
    formState: history.location.state?.formState || 'default',
    nickname: ''
  };

  const createTokenUser = useFetcher(UserResource.createTokenUserShape());
  const sendLoginToken = useFetcher(SendLoginTokenResource.sendLoginTokenShape());
  const fetchSavedActivation = useFetcher(ActivationResource.detailShape());

  async function handleSubmit (formValues: WelcomeFormType, actions: FormikHelpers<WelcomeFormType>) {
    if (formValues.formState === 'createaccount') {
      try {
        const values: schemas.TokenUserCreateType = { email: formValues.email, nickname: formValues.nickname || '' };
        const user: schemas.UserType = await createTokenUser({}, values);
        if (activationId) {
          history.replace({ pathname: `/activations/${activationId}`, search: `?tus=${user.tokenUserSecret}` });
        } else {
          history.replace({ pathname: '/user/home', search: `?tus=${user.tokenUserSecret}` });
        }
      } catch (e) {
        reportException(e, 'createTokenUser failed in Welcome Content handleSubmit');
        actions.setStatus(e.message || e);
        actions.setSubmitting(false);
      }
    } else { // formState === 'default' || formState === 'tokensent'
      try {
        const values: schemas.SendLoginTokenType = { email: formValues.email, ...activationId && { activationId } };
        const [response, savedActivation] = await Promise.all([
          sendLoginToken({}, values) as Promise<schemas.SendLoginTokenResultType>,
          fetchSavedActivation({ id: genSavedActivationId(formValues.email) }).catch(() => { /* ignore */ }) as Promise<schemas.ActivationType | null>
        ]);
        actions.setStatus('');
        actions.setSubmitting(false);
        if (response?.result === 'notauthorized') {
          history.push({ pathname: '/login/password', state: { email: values.email, ...activationId && { activationId } } });
        } else if (response?.result === 'notfound') {
          if (savedActivation) {
            history.replace({
              pathname: `/activations/${savedActivation.activationSecretId}`,
              state: { email: formValues.email, userName: savedActivation.userName }
            });
          } else {
            actions.setFieldTouched('nickname', false);
            actions.setFieldValue('formState', 'createaccount');
          }
        } else if (response?.result === 'sent') {
          actions.setFieldValue('formState', 'tokensent');
        } else {
          throw new Error(`Failed with ${response?.result || 'no response'}`);
        }
      } catch (e) {
        reportException(e, 'sendLoginToken failed in Welcome Content handleSubmit');
        actions.setStatus(e.message || e);
        actions.setSubmitting(false);
      }
    }
  }

  return (
    <div>
      <ActivationBanner />
      <IonGrid className='py-3 px-0'>
        <IonRow className='p-0'>
          <IonCol className='p-0' size={'12'} size-md={'8'} offset-md={'2'} size-xl={'6'} offset-xl={'3'}>
            <div className={'mb-3'}>
              {
                activationId
                  ? <ActivationWelcome activationId={activationId}/>
                  : <IonItem color='transparent' lines='none'>
                    <h4 className={'text-muted text-break'}>Enter your email to access or create an account</h4>
                  </IonItem>
              }
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={WelcomeFormSchema}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <IonList>
                    <UnexpectedFormErrors expectedErrors={['email', 'nickname']}/>
                    <EmailInput name='email' clearShowForm={() => setFieldValue('formState', 'default')}/>
                    {
                      {
                        createaccount: <CreateAccountInputs/>,
                        tokensent: <TokenSentInputs/>,
                        default: <DefaultInputs/>
                      }[values.formState]
                    }
                  </IonList>
                </Form>
              )}
            </Formik>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
}

export function EmailInput ({
  name,
  className = '',
  clearShowForm
}: { name: string, className?: string, clearShowForm: () => void }) {
  const [{ onChange, ...field }, meta] = useField(name);

  return (<>
    <IonItem lines={'full'}>
      <IonLabel><EmailFormLabelIcon /></IonLabel>
      <IonInput className={className} type='email' autocomplete='email' placeholder='Email' onIonChange={e => {
        onChange(e);
        clearShowForm();
      }} {...field}/>
    </IonItem>
    {!!(meta.error && meta.touched) && <IonItem lines={'none'}><IonLabel className={'mt-0'}><IonNote
      color="danger">{meta.error}</IonNote></IonLabel></IonItem>}
  </>);
}

export function DefaultInputs () {
  const { isSubmitting, dirty } = useFormikContext();

  return (<>
    <CreateAnimation
      duration={500}
      iterations={1}
      fromTo={[
        { property: 'opacity', fromValue: '0', toValue: '1' }
      ]}
      play={true}
    >
      <IonItem lines={'none'}>
        <IonLabel>
          <TextSaveButton text='Continue' loadingText='Please wait…' disabled={isSubmitting || !dirty}
                          isLoading={isSubmitting} submitOnEnter={true}/>
        </IonLabel>
      </IonItem>
    </CreateAnimation>
  </>);
}

function CreateAccountInputs () {
  const { isSubmitting, dirty } = useFormikContext();

  return (<>
    <CreateAnimation
      duration={500}
      iterations={1}
      fromTo={[
        { property: 'opacity', fromValue: '0', toValue: '1' }
      ]}
      play={true}
    >
      <div>
        <div className={'pb-3'}>
          <IonFormikInput name='nickname' type='text' autocomplete='name' autocapitalize={'words'} label={<NameFormLabelIcon />} placeholder='Name'/>
        </div>
        <IonItem className='text-wrap' lines={'none'}>
          <div className='small text-muted mb-2'>
            <p>Email is never shared or sold. It is used for notifications and account access.</p>
            <p>Please read the <a href="https://www.sprancer.com/terms">Terms of Use</a> and <a
              href="https://www.sprancer.com/privacy">Privacy policy</a>.</p>
          </div>
        </IonItem>
        <IonItem lines={'none'}>
          <IonLabel>
            <TextSaveButton text='Create Account' loadingText='Creating…' disabled={isSubmitting || !dirty}
                            isLoading={isSubmitting} submitOnEnter={true}/>
          </IonLabel>
        </IonItem>
      </div>
    </CreateAnimation>
  </>);
}

function TokenSentInputs () {
  const { isSubmitting } = useFormikContext<schemas.SendLoginTokenType>();

  return (<>
    <CreateAnimation
      duration={500}
      iterations={1}
      fromTo={[
        { property: 'opacity', fromValue: '0', toValue: '1' }
      ]}
      play={true}
    >
      <IonItem color='transparent' lines='none'>
        <h5 className={'text-break'}>A link has been sent to your email</h5>
      </IonItem>
      <IonItem lines={'none'}>
        <p className={'text-muted text-break'}>
          {isPlatform('hybrid')
            ? <strong>Open the link anywhere on this device to access your account in the app.</strong>
            : <strong>Open the link in any browser to access your account.</strong>
          }
        </p>
      </IonItem>
      <IonItem lines={'none'}>
        <IonLabel>
          <TextSaveButton text='Resend' loadingText='Sending…' disabled={isSubmitting}
                          isLoading={isSubmitting} submitOnEnter={true}/>
        </IonLabel>
      </IonItem>
    </CreateAnimation>
  </>);
}
