import React from 'react';
import { IonBackButton, IonButtons, IonTitle, IonToolbar } from '@ionic/react';
import { UserLayoutPage } from '../../containers/UserLayout';
import { useTokenUserSecret } from '../../libs/tokenUserAuth';
import { UserDropdown } from '../../components/Dropdowns';
import { MissingSomethingWarning } from '../../components/Warnings';

export default function UserActivationLinkExpired () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content />}
    />
  );
}

function Header () {
  const { tusifyUrl } = useTokenUserSecret();

  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton text='Cancel' defaultHref={tusifyUrl('/user/notifications')}/>
      </IonButtons>
      <IonTitle>New Connection</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

function Content () {
  return (<div className={'ion-padding'}>
    <MissingSomethingWarning
      warningIcon={true}
      title={'Link Expired'}
      message={'Please reach out to the business for a new link.'}
    />
  </div>);
}
