import { BaseBusinessResource } from './api';
import { arrays, schemas, channels, maps } from 'sprancer-shared';
import { format, parseISO } from 'date-fns';

export class PostThread {
  messages: MessageResource[];
  firstMsg: MessageResource;
  lastMsg: MessageResource;
  dmChannelIds: string[];

  constructor (messages: MessageResource[], firstMsg: MessageResource, lastMsg: MessageResource, dmChannelIds: string[]) {
    this.messages = messages;
    this.firstMsg = firstMsg;
    this.lastMsg = lastMsg;
    this.dmChannelIds = dmChannelIds;
  }

  threadId (): string {
    return this.firstMsg.threadId;
  }

  threadChannelId (): string {
    return channels.genBusinessChannelId(this.firstMsg.businessId);
  }

  threadGroupIds (): string[] {
    return this.messages.length > 0 ? this.messages[0].groupIds : [];
  }

  dateRange (): string {
    if (this.firstMsg.formattedDate() !== this.lastMsg.formattedDate()) {
      return (`${this.firstMsg.formattedDate()} - ${this.lastMsg.formattedDate()}`);
    } else {
      return (this.firstMsg.formattedDate());
    }
  }

  isDirect (): boolean {
    return channels.isDirectThreadId(this.threadId());
  }
}

export class DirectMessageThread {
  messages: MessageResource[];
  threadMessages: MessageResource[];
  firstMsg: MessageResource;
  lastMsg: MessageResource;

  constructor (messages: MessageResource[], threadMessages: MessageResource[]) {
    this.messages = messages;
    this.threadMessages = threadMessages;
    this.firstMsg = messages[0];
    this.lastMsg = messages[messages.length - 1];
  }

  threadId (): string {
    return this.firstMsg.threadId;
  }

  channelId (): string {
    return this.messages[0].channelId;
  }

  threadGroupIds (): string[] {
    return this.threadMessages.length > 0 ? this.threadMessages[0].groupIds : [];
  }

  dateRange (): string {
    if (this.firstMsg.formattedDate() !== this.lastMsg.formattedDate()) {
      return (`${this.firstMsg.formattedDate()} - ${this.lastMsg.formattedDate()}`);
    } else {
      return (this.firstMsg.formattedDate());
    }
  }
}

export interface MessageResource extends schemas.MessageType {}
export class MessageResource extends BaseBusinessResource {
  constructor (init?: schemas.MessageCreateType) {
    super();
    Object.assign(this, init);
  }

  fromId (): string {
    return this.createdById;
  }

  fromMe (customerOrBusinessId: string): boolean {
    return this.createdById === customerOrBusinessId;
  }

  formattedDate (): string {
    return format(parseISO(this.createdAt), 'MMMM do');
  }

  static messageCreateShape<T extends typeof BaseBusinessResource> (this: T) {
    return {
      ...this.createShape(),
      fetch: (params: { businessId: string }, body: schemas.MessageCreateType
      ) => {
        return this.fetch('post', this.listUrl(params), body);
      }
    };
  }

  static customExpiryListShape<T extends typeof BaseBusinessResource> (this: T, { dataExpiryLength }: { dataExpiryLength: number }) {
    return {
      ...this.listShape(),
      options: { ...this.listShape().options, dataExpiryLength }
    };
  }

  /**
   * Partitions the messages into threads.  The threads are sorted by activity (most recent first) and messages within
   * each thread are partioned by channel.  Channels are sorted by activity (most recent first) and messages within
   * each channel are sorted most recent last.
   * @param allMessages
   * @returns threadsById a map of threadId to ConnectionThread
   */
  static partitionMessagesByThread (allMessages: MessageResource[]): {
    postThreadsById: Map<string, PostThread>,
    dmThreadsByIdAndChannelId: Map<string, DirectMessageThread> } {
    // sort all messages into descending order.  This means the first message for a thread will be most recently active
    // and the last message for a thread will be the first message in that thread.
    arrays.sortBy<MessageResource>(allMessages, 'createdAt', false);

    const postThreadDataById = new maps.MapWithDefault<string, { messages: MessageResource[], newestDirectMessage: MessageResource | undefined, dmChannelIds: Set<string> }>(
      [],
      () => ({ messages: [], newestDirectMessage: undefined, dmChannelIds: new Set() })
    );
    const dmThreadDataByIdAndChannelId = new maps.MapWithDefault<string, { messages: MessageResource[] }>(
      [],
      () => ({ messages: [] })
    );

    for (const m of allMessages) {
      if (channels.isDirectThreadId(m.threadId)) {
        // direct chat, not associated with any post
        const thread = dmThreadDataByIdAndChannelId.get(m.threadId + m.channelId);
        thread.messages.push(m);
      } else {
        const postThread = postThreadDataById.get(m.threadId);
        if (channels.isDirectChannelId(m.channelId)) {
          // direct chat associated with a post.  Add it to the dm thread
          const dmThread = dmThreadDataByIdAndChannelId.get(m.threadId + m.channelId);
          dmThread.messages.push(m);
          // set this as the newestDM if it hasn't already been set
          if (!postThread.newestDirectMessage) {
            postThread.newestDirectMessage = m;
          }
          // and reference the channel is the post thread
          postThread.dmChannelIds.add(m.channelId);
        } else {
          // this is a post message
          postThread.messages.push(m);
        }
      }
    }

    const postThreadsById = new Map<string, PostThread>();
    postThreadDataById.forEach((data) => {
      // Make sure there are actual post messages. They may have expired in which case this should not show up in the list of posts
      if (data.messages.length > 0) {
        // Messages were inserted 'latest first', get the latest message.
        const lastMsg = data.newestDirectMessage || data.messages[0];
        // Now reverse them so they are newest first.
        data.messages.reverse();

        postThreadsById.set(data.messages[0].threadId, new PostThread(data.messages, data.messages[0], lastMsg, Array.from(data.dmChannelIds)));
      }
    });

    const dmThreadsByIdAndChannelId = new Map<string, DirectMessageThread>();
    dmThreadDataByIdAndChannelId.forEach((data) => {
      // Now reverse them so they are newest first.
      data.messages.reverse();
      // const lastMsg = data.newestDirectMessage || data.messages[data.messages.length - 1];
      dmThreadsByIdAndChannelId.set(
        data.messages[0].threadId + data.messages[0].channelId,
        new DirectMessageThread(data.messages, postThreadsById.get(data.messages[0].threadId)?.messages || []));
    });
    return { postThreadsById, dmThreadsByIdAndChannelId };
  }

  pk () {
    return this.id;
  }

  static urlRoot = '/messages';
}
