import { useHistory } from 'react-router';
import { IonBackButton, IonButtons, IonItem, IonLabel, IonList, IonTitle, IonToolbar } from '@ionic/react';
import { useFetcher } from 'rest-hooks';
import { schemas } from 'sprancer-shared';
import { Form, Formik, FormikHelpers } from 'formik';
import { reportException } from '../../libs/errors';
import { AvatarRadioInput, IonFormikInput, UnexpectedFormErrors } from '../../components/Forms';
import { TextSaveButton } from '../../components/FormButtons';
import React from 'react';
import { UserLayoutPage } from '../../containers/UserLayout';
import { useGetCurrentUser, UserResource } from '../../models/user';
import { UserDropdown } from '../../components/Dropdowns';
import { useTokenUserSecret } from '../../libs/tokenUserAuth';
import * as yup from 'yup';

export default function UserProfileDetailsEdit () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
    />
  );
}

function Header () {
  const { tusifyUrl } = useTokenUserSecret();

  return (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton text='Cancel' defaultHref={tusifyUrl('/user/profile')}/>
        </IonButtons>
        <IonTitle>Edit Profile</IonTitle>
        <UserDropdown slot="end"/>
      </IonToolbar>
    </>
  );
}

const ProfileDetailsEditSchema = yup.object({
  nickname: yup.string().required('required'),
  avatar: yup.string().notRequired()
});

function Content () {
  const { tus } = useTokenUserSecret();
  const history = useHistory();
  const user = useGetCurrentUser();
  const patch = useFetcher(UserResource.partialUpdateShape());

  async function handleSubmit (values: schemas.UserPatchType, actions: FormikHelpers<schemas.UserPatchType>) {
    try {
      await patch({ id: user.userId, ...tus && { tus } }, values);
      actions.setSubmitting(false);
      history.goBack();
    } catch (e) {
      reportException(e, 'handleSubmit failed in UserProfileDetailsEdit Content');
      actions.setStatus(e.message || e);
      actions.setSubmitting(false);
    }
  }

  const initialValues: (schemas.UserPatchType) = { nickname: user.nickname || '', avatar: user.avatar || '' };

  return (
    <IonList>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={ProfileDetailsEditSchema}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <UnexpectedFormErrors expectedErrors={['avatar', 'nickname']}/>
            <IonFormikInput
              name='nickname'
              stackedLabel={'Name'}
              placeholder='Name'
              autocomplete={'name'}
              autocapitalize={'words'}
              type='text'
            />
            <AvatarRadioInput name={'avatar'} label={'Avatar'}/>
            <IonItem lines="none" className='text-center'>
              <IonLabel>
                <TextSaveButton disabled={isSubmitting || !dirty} isLoading={isSubmitting} />
              </IonLabel>
            </IonItem>
          </Form>
        )}
      </Formik>
    </IonList>
  );
}
