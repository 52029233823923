import React, { Suspense, useEffect, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';

import Login from '../views/Auth/Login';
import {
  IonCol, IonContent, IonGrid, IonHeader,
  IonPage,
  IonRow
} from '@ionic/react';
import ErrorBoundary from '../views/Errors/ErrorBoundary';
import ForgotPassword from '../views/Auth/ForgotPassword';
import CreateAccount from '../views/Auth/CreateAccount';
import UserProfileCreate from '../views/Auth/UserProfileCreate';
import { Loading } from '../components/Loading';
import { Plugins } from '@capacitor/core';
import Welcome from '../views/Auth/Welcome';
import { Redirect } from 'react-router';
import Activation from '../views/Activations/Activation';
import ActivationLinkExpired from '../views/Activations/ActivationLinkExpired';
import Unsubscribe from '../views/Notifications/Unsubscribe';

const { SplashScreen } = Plugins;

export const ROUTES = [
  { path: '/activations/:activationId', exact: true, name: 'Activation', component: Activation },
  { path: '/activations/:activationId/linkexpired', exact: true, name: 'Link Expired', component: ActivationLinkExpired },

  { path: '/login/password', exact: true, component: Login },
  { path: '/login/create', exact: true, component: CreateAccount },
  { path: '/login/forgot', exact: true, component: ForgotPassword },
  { path: '/login/welcome', exact: true, component: Welcome },

  { path: '/profileCreate', exact: true, component: UserProfileCreate },

  { path: '/unsubscribe/:userId/:urlEncodedEmail', component: Unsubscribe }
];

export default function NoAuthLayout () {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    SplashScreen.hide();
  }, []);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <Switch>
          {ROUTES.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              render={() => (
                <IonPage>
                  <ErrorBoundary>
                    <Suspense fallback={<Loading />}>
                      <route.component/>
                    </Suspense>
                  </ErrorBoundary>
                </IonPage>
              )}
            />
          ))}
          <Redirect to={'/login/welcome'} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
}

export const NoAuthLayoutContext = React.createContext<{ contentRef: React.RefObject<HTMLIonContentElement> | null }>({ contentRef: null });

export function NoAuthLayoutPage ({ header, content, contentStyle = {} }:
  {
    header: JSX.Element,
    content: JSX.Element,
    contentStyle?: Record<string, string>
  }) {
  const contentRef = useRef<HTMLIonContentElement>(null);

  return (
    <NoAuthLayoutContext.Provider value={{ contentRef: contentRef }}>
      <IonHeader>
        {header}
      </IonHeader>
      <IonContent forceOverscroll={false} fullscreen={true} ref={contentRef} style={contentStyle}>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol size={'12'} className='p-0 m-0'>
              {content}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </NoAuthLayoutContext.Provider>
  );
}
