import { schemas } from 'sprancer-shared';
import { Form, Formik, FormikHelpers } from 'formik';
import { reportException } from '../../libs/errors';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonItem,
  IonLabel,
  IonList,
  IonNote, IonTitle,
  IonToolbar
} from '@ionic/react';
import {
  AvatarRadioInput, ImageFileDropInput,
  IonFormikInput,
  IonFormikTextarea,
  UnexpectedFormErrors
} from '../../components/Forms';
import { TextSaveButton } from '../../components/FormButtons';
import React from 'react';
import { UserLayoutPage } from '../../containers/UserLayout';
import { UserDropdown } from '../../components/Dropdowns';
import { BusinessResource } from '../../models/business';
import { useHistory, useParams } from 'react-router';
import { useFetcher, useResource } from 'rest-hooks';
import { s3UploadBusinessLogo } from '../../libs/storage';
import { generatePublicActivationLink } from '../../components/Activations';
import { RefreshIcon } from '../../libs/icons';

export default function SettingsEdit () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content />}
    />
  );
}

function Header () {
  const { businessId } = useParams<{ businessId: string }>();

  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton text='Cancel' defaultHref={`/businesses/${businessId}/settings`}/>
      </IonButtons>
      <IonTitle>Edit Business</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

function Content () {
  const history = useHistory();
  const { businessId } = useParams<{ businessId: string }>();
  const business = useResource(BusinessResource.detailShape(), { id: businessId });

  const update = useFetcher(BusinessResource.updateShape());

  async function handleSubmit (values: schemas.BusinessUpdateType & { imageFile?: File }, actions: FormikHelpers<schemas.BusinessUpdateType>) {
    try {
      if (values.imageFile) {
        try {
          values.logoS3ImageName = await s3UploadBusinessLogo(values.imageFile, { shouldResize: 'true' });
        } catch (e) {
          // image upload failed report exception and return from the function early
          reportException(e, 's3UploadBusinessLogo failed in SettingsEdit Content handleSubmit');
          actions.setStatus(e.message || e);
          actions.setSubmitting(false);
          return;
        }
      }
      await update({ id: business.id }, values);
      actions.setSubmitting(false);
      history.replace(`/businesses/${businessId}/settings`);
    } catch (e) {
      reportException(e, 'handleSubmit failed in SettingsEdit Content');
      actions.setStatus(e.message || e);
      actions.setSubmitting(false);
    }
  }

  const initialValue: schemas.BusinessUpdateType = business;
  initialValue.resetActiviationSecretId = false;

  return (<>
      <IonList>
        <IonItem lines='none'><IonNote className={'small'}>Enter a few details about yourself. These will be shared with your
          customers.</IonNote></IonItem>

        <Formik
          initialValues={initialValue}
          onSubmit={handleSubmit}
          validationSchema={schemas.BusinessUpdateSchema}
        >
          {({ isSubmitting, dirty, setFieldValue, values }) => (
            <Form>
              <UnexpectedFormErrors expectedErrors={['contactName', 'businessName', 'avatar', 'welcomeMessage']}/>
              <AvatarRadioInput name={'avatar'} stackedLabel={'Avatar'}/>
              <IonFormikInput
                name='contactName'
                stackedLabel={'Contact Name'}
                placeholder={'Contact Name'}
                autocomplete={'given-name'}
                autocapitalize={'words'}
                type='text'
              />
              <IonFormikInput
                name='businessName'
                stackedLabel={'Business Name'}
                placeholder={'Business Name'}
                autocapitalize={'words'}
                type='text'
              />
              <IonItem><IonLabel position={'stacked'}>Logo</IonLabel></IonItem>
              <IonItem><ImageFileDropInput
                name='imageFile'
                existingImageUrl={values.logoS3ImageName && business.logoImageUrl ? business.logoImageUrl : ''}
                removeExistingImage={() => setFieldValue('logoS3ImageName', '')}
              /></IonItem>
              <IonItem><IonLabel position={'stacked'}>New Customer Link</IonLabel>
                <p className={'text-break'}><div className={'d-flex'}><span className={'my-auto mr-2'}>{ values.resetActiviationSecretId
                  ? <><del>{generatePublicActivationLink(business)}</del><p><em>Save to generate new link</em></p></>
                  : business.publicActivationSecretId ? generatePublicActivationLink(business) : <em>None</em> }
                </span>
                <IonButton
                  className='mr-1'
                  color='danger'
                  fill={values.resetActiviationSecretId ? 'outline' : 'solid' }
                  onClick={() => {
                    const confirmed = values.resetActiviationSecretId || !business.publicActivationSecretId || window.confirm(
                      'Are you sure you want to recreate this link?  The existing link will stop working.'
                    );
                    if (confirmed) {
                      setFieldValue('resetActiviationSecretId', !values.resetActiviationSecretId);
                    }
                  }}>
                  <RefreshIcon size='2em'/>
                </IonButton></div>
                </p>
              </IonItem>
              <IonFormikTextarea
                name='welcomeMessage'
                stackedLabel={'Welcome Message'}
                helpText='Customers see this when they connect.'
                placeholder='Welcome Message'
                rows={6}
              />
              <IonItem lines="none" className='text-center'>
                <IonLabel>
                  <TextSaveButton disabled={isSubmitting || !dirty} isLoading={isSubmitting} />
                </IonLabel>
              </IonItem>
            </Form>
          )}
        </Formik>
      </IonList>
    </>
  );
}
