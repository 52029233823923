import { IonPage, IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router';
import ErrorBoundary from '../views/Errors/ErrorBoundary';
import React from 'react';

type OutletRoutes = {
  path: string;
  name: string;
  component: JSX.Element;
}

// IonRouterOutlet doesn't support "NotFound" routes properly, so in order to get NotFound routes with the animation
// benefits of IonRouterOutlet we have to have a Switch with Routes that all go to an IonRouterOutlet with the same
// routes, something like:
// export default function UserNotifications () {
//   const { tusifyUrl } = useTokenUserSecret();
//
//   return (
//     <Switch>
//       <Route exact path='/user/notifications' name='Notifications'><Outlet /></Route>
//       <Route exact path='/user/notifications/edit' name='Edit Notifications'><Outlet /></Route>
//       <Redirect to={tusifyUrl('/user/notifications')} />
//     </Switch>
//   );
// }
//
// function Outlet() {
//   return (
//     <IonRouterOutlet>
//       <Route exact path='/user/notifications' name='Notifications'>
//         <IonPage>
//           <ErrorBoundary>
//             <UserNotificationsOverview />
//           </ErrorBoundary>
//         </IonPage>
//       </Route>
//       <Route exact path='/user/notifications/edit' name='Edit Notifications'>
//         <IonPage>
//           <ErrorBoundary>
//             <UserNotificationsEdit/>
//           </ErrorBoundary>
//         </IonPage>
//       </Route>
//     </IonRouterOutlet>
//   );
// }
//
// IonRouterOutletWrapper is a helper to avoid copy+pasting route names so the above becomes:
//
// export default function UserNotifications () {
//   const { tusifyUrl } = useTokenUserSecret();
//
//   // IonRouterOutletWrapper for an explanation of this nonsense.
//   const routes = [
//     { path: '/user/notifications', name: 'Notifications', component: <UserNotificationsOverview />},
//     { path: '/user/notifications/edit', name: 'Edit Notifications', component: <UserNotificationsEdit />}
//   ];
//
//   return (
//     <Switch>
//       <Route exact path={routes[0].path} name={routes[0].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
//       <Route exact path={routes[1].path} name={routes[1].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
//       <Redirect to={tusifyUrl('/user/notifications')} />
//     </Switch>
//   );
// }
//

export function IonRouterOutletWrapper ({ outletRoutes }: { outletRoutes: OutletRoutes[] }) {
  return (
    <IonRouterOutlet>
      {outletRoutes.map(({ path, name, component }) => (
        <Route key={path} exact path={path} name={name}>
          <IonPage>
            <ErrorBoundary>
              {component}
            </ErrorBoundary>
          </IonPage>
        </Route>
      ))}
    </IonRouterOutlet>
  );
}
