import React, { Component, ErrorInfo } from 'react';
import { Redirect, Route } from 'react-router';
import UserActivationOverview from './UserActivationOverview';
import UserActivationLinkExpired from './UserActivationLinkExpired';
import { useTokenUserSecret } from '../../libs/tokenUserAuth';
import { Switch } from 'react-router-dom';
import { IonRouterOutletWrapper } from '../../components/RouterOutlet';

export default function UserActivations () {
  const { tusifyUrl } = useTokenUserSecret();

  // IonRouterOutletWrapper for an explanation of this nonsense.
  const routes = [
    { path: '/activations/:activationId', name: 'Activation', component: <ActivationErrorBoundary><UserActivationOverview/></ActivationErrorBoundary> },
    { path: '/activations/:activationId/linkexpired', name: 'Link Expired', component: <ActivationErrorBoundary><UserActivationLinkExpired/></ActivationErrorBoundary> }
  ];

  return (
    <Switch>
      <Route exact path={routes[0].path} name={routes[0].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[1].path} name={routes[1].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Redirect to={tusifyUrl('/user/home')} />
    </Switch>
  );
}

class ActivationErrorBoundary extends Component {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public state: { error: any } = {
    error: null
  };

  public static getDerivedStateFromError (error: Error) {
    return { error };
  }

  public componentDidCatch (error: Error, info: ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, info);
  }

  public render () {
    if (this.state.error?.status === 404 || this.state.error?.status === 410) {
      return (<UserActivationLinkExpired />);
    }

    return this.props.children;
  }
}
