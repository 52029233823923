import React from 'react';
import { Route, Redirect, useLocation, RouteProps } from 'react-router-dom';
import { useAppContext } from '../libs/context';
import { useTokenUserSecret, useTusStorage } from '../libs/tokenUserAuth';
import { useHistory } from 'react-router';
import { reportException } from '../libs/errors';
export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
  isAllowed: boolean;
  restrictedPath: string;
  authenticationPath: string;
}

type AuthRouteProps = Required<Pick<RouteProps, 'component'>> & Omit<RouteProps, 'children' | 'render'>;

export function AuthenticatedRoute ({ component: Component, ...rest }: AuthRouteProps) {
  const { authenticated } = useAppContext();
  const { pathname, search } = useLocation();

  return (
    <Route {...rest}
      render={(props) => authenticated === true
        ? <Component {...props} />
        : <Redirect to={`/login/welcome?redirect=${pathname}${search}`} />}

    />
  );
}

export function AuthOrUnauthRoute ({ authComponent: AuthComponent, unauthComponent: UnauthComponent, ...rest }:
  { authComponent: React.FunctionComponent, unauthComponent: React.FunctionComponent, path: string }) {
  const history = useHistory();
  const { authenticated, storedTus } = useAppContext();
  const { tus } = useTokenUserSecret();

  useTusStorage(tus, authenticated);

  if (!tus && !authenticated && storedTus) {
    // console.log('Replace storedTus from AOUR ' + JSON.stringify(history.location) + ' s:' + storedTus);
    history.replace({ ...history.location, search: `?tus=${storedTus}` });
    return (<></>);
  }

  // console.log('Choosing from AOUR: ' + ((authenticated === true || tus) ? '<AuthComponent />' : '<UnauthComponent /> ') + JSON.stringify(history.location));

  return (<Route {...rest} render={() => (authenticated === true || tus) ? <AuthComponent /> : <UnauthComponent /> } />);
}

export function TusableAuthenticatedRoute ({ component: Component, ...rest }: AuthRouteProps) {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { authenticated, storedTus } = useAppContext();
  const { tus } = useTokenUserSecret();

  useTusStorage(tus, authenticated);

  if (!tus && !authenticated && storedTus) {
    // console.log('Replace storedTus from TAR: ' + JSON.stringify(history.location) + ' s:' + storedTus);
    history.replace({ ...history.location, search: `?tus=${storedTus}` });
    return (<></>);
  }

  if (!tus && !authenticated) {
    const { bizPrefixedId, tokenUserSecret } = convertOldStyleConnectionId(pathname);
    if (bizPrefixedId && tokenUserSecret) {
      reportException(new Error('Warning ConvertedOldStyleConnectionId'), 'convertOldStyleConnectionId warned in Auth TusableAuthenticatedRoute');
      return <Redirect to={`/connections/${bizPrefixedId}?tus=${tokenUserSecret}`}/>;
    }
  }

  if (!(authenticated === true || tus)) {
    // console.log('Redirecting from TAR: ' + JSON.stringify(history.location));
  }

  return (
    <Route {...rest}
      render={(props) => (authenticated === true || tus)
        ? <Component {...props} />
        : <Redirect to={`/login/welcome?redirect=${pathname}${search}`} />}

    />
  );
}

function querystring (name: string, url = window.location.href) {
  name = name.replace(/[[]]/g, '\\$&');

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function UnauthenticatedRoute ({ component: Component, path, exact = false }: { component: React.FunctionComponent; path: string; exact?: boolean}) {
  const history = useHistory();
  const redirect = querystring('redirect');
  const { authenticated, storedTus } = useAppContext();
  const { tus } = useTokenUserSecret();

  useTusStorage(tus, authenticated);

  if (!tus && !authenticated && storedTus) {
    // console.log('Replace storedTus from UR: ' + JSON.stringify(history.location) + ' s:' + storedTus);
    history.replace({ ...history.location, search: `?tus=${storedTus}` });
    return (<></>);
  }

  if (authenticated === true || tus) {
    // console.log('Redirecting from UR: ' + JSON.stringify(history.location));
  }

  return (
    <Route path={path} exact={exact} render={() => (authenticated === true || tus)
      ? <Redirect to={redirect === '' || redirect === null ? '/' : redirect} />
      : <Component /> }
    />
  );
}

const CONNECTION_PATH = /\/connections\/([^/]+)/;
const DELIMITER = 'Z';
export function convertOldStyleConnectionId (pathname: string): { bizPrefixedId: string, tokenUserSecret: string } {
  const match = CONNECTION_PATH.exec(pathname);
  if (match) {
    const connectionSecretId = match[1];
    const [version, newBusinessId, connectionId, , token] = connectionSecretId.split(DELIMITER);
    if (version === '0' && newBusinessId && connectionId && token) {
      const bizPrefixedId = ['3', newBusinessId, connectionId].join(DELIMITER);
      const tokenUserSecret = ['2', connectionId, token].join(DELIMITER);
      return { bizPrefixedId, tokenUserSecret: tokenUserSecret };
    }
  }
  return { bizPrefixedId: '', tokenUserSecret: '' };
}
