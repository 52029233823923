import React, { useState } from 'react';
import { UserLayoutPage } from '../../containers/UserLayout';
import { IonButtons, IonTitle, IonToolbar, IonBackButton, IonRadioGroup, IonLabel, IonItem, IonRadio } from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { useGetCurrentUser, UserResource } from '../../models/user';
import { useTokenUserSecret } from '../../libs/tokenUserAuth';
import { useFetcher } from 'rest-hooks';
import { ConnectionResource } from '../../models/connection';
import { Form, Formik, FormikHelpers } from 'formik';
import { reportException } from '../../libs/errors';
import { useHistory } from 'react-router-dom';
import { TextSaveButton } from '../../components/FormButtons';
import { UnexpectedFormErrors } from '../../components/Forms';
import { ConnectionMessageResource } from '../../models/connectionMessage';
import { useBackground } from '../../libs/background';

export default function UserNewConnectionHowTo () {
  const { contentStyle } = useBackground();

  return (
    <UserLayoutPage
      header={<Header/>}
      content={<UserNewConnectionHowToContent/>}
      // turn off the ionic shadow '--background' varible and use a css variable.  --background was flickering on ios.
      contentStyle={contentStyle}
    />
  );
}

function Header () {
  const { tusifyUrl } = useTokenUserSecret();

  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton defaultHref={tusifyUrl('/user/home')}/>
      </IonButtons>
      <IonTitle>New Business</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

export function UserNewConnectionHowToContent () {
  const [alreadyUsing, setAlreadyUsing] = useState('');

  return (<div className={'px-3'}>
    <h5 className={'px-1 mb-5'}>How to connect with a business</h5>
    <div className={'mb-5'}>
      <p>Is the business already using Sprancer?</p>
      <IonRadioGroup className={'mb-5'} value={alreadyUsing} onIonChange={e => setAlreadyUsing(e.detail.value)}>
        <IonItem>
          <IonLabel>Yes</IonLabel>
          <IonRadio slot="start" value="yes" />
        </IonItem>
        <IonItem>
          <IonLabel>No</IonLabel>
          <IonRadio slot="start" value="no" />
        </IonItem>
        <IonItem>
          <IonLabel>I don&apos;t know</IonLabel>
          <IonRadio slot="start" value="dontknow" />
        </IonItem>
      </IonRadioGroup>
    </div>
    {
      alreadyUsing === 'yes'
        ? <>
          <p>Businesses connect with customers by sharing a link.</p>
          <h2>Ask the business to share a Sprancer link with you.</h2>
        </>
        : alreadyUsing === 'no'
          ? <>
            <p>Tell us about the business.  We&apos;ll get them set up.</p>
            <ConnectWithSprancerForm />
          </>
          : alreadyUsing === 'dontknow'
            ? <>
              <p>Tell us about the business you&apos;d like to connect to.  We&apos;ll figure it out.</p>
              <ConnectWithSprancerForm />
            </>
            : <></>
    }
  </div>);
}

const ACTIVATIONS_PATH_REGEX = /\/activations\/([^/]+)/;

function ConnectWithSprancerForm (): JSX.Element {
  const user = useGetCurrentUser();
  const { tus, tusifyUrl } = useTokenUserSecret();

  const history = useHistory();
  const create = useFetcher(ConnectionResource.createForActivationShape());
  const fetchUser = useFetcher(UserResource.detailShape());

  const activationId = ACTIVATIONS_PATH_REGEX.exec(user.connectToSprancerPath || '')?.[1];
  if (!activationId) {
    return (<h2>Email <strong>support@sprancer.com</strong></h2>);
  }

  async function handleSubmit (values: Record<string, string>, actions: FormikHelpers<Record<string, string>>) {
    try {
      const connection = await create({ ...tus && { tus } }, { activationSecretId: activationId || '' });
      fetchUser({ id: 'current', ...tus && { tus } }).catch(e => {
        reportException(e, 'fetchUser failed in UserNewConnectionHowTo ConnectWithSprancerForm');
      });
      actions.setSubmitting(false);
      history.replace(
        {
          pathname: `/connections/${connection.id}/messages/${ConnectionMessageResource.directThreadId(connection.id)}`,
          search: tusifyUrl(''), // use tusifyUrl with an empty string to create the "?tus=<tus>" search string if necessary
          state: { newMessage: true, fromNewConnectionHowTo: true }
        });
    } catch (e) {
      reportException(e, 'handleSubmit failed in UserNewConnectionHowTo ConnectWithSprancerForm');
      actions.setStatus(e.message || e);
      actions.setSubmitting(false);
    }
  }

  return (<>
    <Formik
      initialValues={{}}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <UnexpectedFormErrors expectedErrors={[]}/>
          <TextSaveButton isLoading={isSubmitting} text={'Connect to Sprancer'} loadingText={'Connecting…'}/>
        </Form>
      )}
    </Formik>
  </>);
}
