import { AmplifyResource } from './api';
import { schemas } from 'sprancer-shared';
import { AbstractInstanceType, Resource, SimpleResource, useFetcher } from 'rest-hooks';
import { useTokenUserSecret, useTusableResource } from '../libs/tokenUserAuth';

export function useGetCurrentUser (): UserResource {
  return useTusableResource(UserResource.detailShape(), { id: 'current' });
}

export function useRefetchCurrentUser (): () => Promise<void> {
  const { tus } = useTokenUserSecret();

  const fetchUser = useFetcher(UserResource.detailShape());
  return () => fetchUser({ id: 'current', ...tus && { tus } });
}

export class UserNotFoundError extends Error {
  cause: Error | null;
  constructor (e: Error | string) {
    super(typeof e === 'string' ? e : e.message);
    // Ensure the name of this error is the same as the class name
    this.name = this.constructor.name;
    this.cause = typeof e === 'string' ? null : e;
  }

  // Add a private property so typescript will distinguish between errors even though they have the same structure.
  private disableTypescriptStructuralCompatibility = true;
}

export interface UserResource extends schemas.UserType {}
export class UserResource extends AmplifyResource {
  constructor (init?: schemas.UserType) {
    super();
    Object.assign(this, init);
  }

  pk () {
    return 'current';
  }

  hasBusiness (): boolean {
    for (const p of this.permissions || []) {
      if (/\/businesses\/[^/]+/.exec(p.path)) {
        return true;
      }
    }
    return false;
  }

  defaultFrequency (): schemas.FrequencyType {
    return this.notificationFrequency || schemas.DEFAULT_FREQUENCY;
  }

  defaultDirectMessageFrequency (): schemas.FrequencyType {
    const f = this.directMessageNotificationFrequency || schemas.DEFAULT_DIRECT_MESSAGE_FREQUENCY;
    return f === '5 minutes' ? 'instant' : f;
  }

  static createCognitoUserShape<T extends typeof Resource> (this: T) {
    return {
      ...this.createShape(),
      fetch: (params: Readonly<Record<string, string | number>>, body?: Readonly<schemas.CognitoUserCreateType>) => {
        return this.fetch('post', '/users', body);
      }
    };
  }

  static createTokenUserShape<T extends typeof Resource> (this: T) {
    return {
      ...this.createShape(),
      fetch: (params: Readonly<Record<string, string | number>>, body?: Readonly<schemas.TokenUserCreateType>) => {
        return this.fetch('post', '/tokenusers', body);
      }
    };
  }

  static detailShape<T extends typeof Resource> (this: T) {
    return {
      ...super.detailShape(),
      fetch: (params: Record<string, string>) => {
        return this.fetch('get', this.url(params))
          .catch(e => {
            if (e.status === 404) {
              e.cause = new UserNotFoundError(e);
            }
            throw e;
          });
      },
      schema: this.asSchema()
    };
  }

  //
  // Tus support
  //

  static url<T extends typeof SimpleResource> (
    this: T,
    urlParams: { tus?: string } & Partial<AbstractInstanceType<T>>
  ): string {
    const pk = this.pk(urlParams);
    const { tus } = urlParams;
    const tusParams = new URLSearchParams({ ...tus && { tus } });

    if (pk !== undefined) {
      if (this.urlRoot.endsWith('/')) {
        return `${this.urlRoot}/${pk}?${tusParams}`;
      }

      return `${this.urlRoot}/${pk}?${tusParams}`;
    }
    return `${this.urlRoot}/?${tusParams}`;
  }

  static urlRoot = '/users';
}

export interface CustomerUserResource extends schemas.UserType {}
export class CustomerUserResource extends AmplifyResource {
  constructor (init?: schemas.UserType) {
    super();
    Object.assign(this, init);
  }

  pk () {
    return 'current';// this.userId;
  }

  static createCustomerTokenUserShape<T extends typeof Resource> (this: T) {
    return {
      ...this.createShape(),
      fetch: (params: Readonly<Record<string, string | number>>, body?: Readonly<schemas.TokenUserCreateType>) => {
        return this.fetch('post', '/tokenusers', body).then(u => ({ tokenUserSecret: u.tokenUserSecret }));
      }
    };
  }

  static urlRoot = '/users';
}

export interface SendLoginTokenResource extends schemas.SendLoginTokenResultType {}
export class SendLoginTokenResource extends AmplifyResource {
  constructor (init?: schemas.SendLoginTokenResultType) {
    super();
    Object.assign(this, init);
  }

  pk () {
    return '0';
  }

  static sendLoginTokenShape<T extends typeof Resource> (this: T) {
    return {
      ...this.createShape(),
      fetch: (params: Readonly<Record<string, string | number>>, body?: Readonly<schemas.SendLoginTokenType>) => {
        return this.fetch('post', '/users/sendlogintoken', body);
      }
    };
  }

  static urlRoot = '/users/sendlogintoken';
}
