import { UserLayoutPage } from '../../containers/UserLayout';
import {
  IonButton,
  IonButtons,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader, IonMenuButton,
  IonTitle,
  IonToolbar,
  IonToggle, isPlatform
} from '@ionic/react';
import { useGetCurrentUser } from '../../models/user';
import React, { useContext } from 'react';
import { AvatarImage } from '../../components/Avatars';
import { UserDropdown } from '../../components/Dropdowns';
import { AppContext, useAppContext } from '../../libs/context';
import { useHistory } from 'react-router';
import { useTokenUserSecret } from '../../libs/tokenUserAuth';

export default function UserProfileOverview () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content />}
    />
  );
}

function Header () {
  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonMenuButton autoHide={true}/>
      </IonButtons>
      <IonTitle>Profile</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

function Content () {
  const { tus, tusifyUrl } = useTokenUserSecret();

  const user = useGetCurrentUser();
  const history = useHistory();
  const { signOut } = useAppContext();

  const { fontSize, storeAndSetFontSize } = useContext(AppContext);

  return (<>
    <IonListHeader lines='inset'>My Profile
      <IonButton
        className='ml-auto'
        color="primary"
        fill="clear"
        routerLink={tusifyUrl('/user/profile/editDetails')}
      >Edit</IonButton>
    </IonListHeader>
    <IonList>
      <IonItem lines='none'><AvatarImage size='md' avatar={user.avatar}/><IonLabel
        className={'ml-1'}>{user.nickname || <i>No Name</i>}</IonLabel></IonItem>
      <IonItem lines='none'>
        <IonLabel>
          <h2>Email Address</h2>
          <p>{user.maskedEmail || 'None'}</p>
        </IonLabel>
      </IonItem>
    </IonList>
    <IonListHeader lines='inset'>App</IonListHeader>
    <IonList>
      { isPlatform('hybrid') && isPlatform('ios') &&
        <>

          <IonItem lines='none'>
            <IonToggle slot='start' checked={fontSize === 'large'} onIonChange={e => storeAndSetFontSize(e.detail.checked ? 'large' : '')} />
            <IonLabel className="text-wrap">
              Use Large Font Size
            </IonLabel>
          </IonItem>
        </>
      }
      <IonItem lines='none'>
        <IonLabel className="text-wrap">
          <IonButton size={'default'} className='text-wrap' expand={'block'} routerLink={tusifyUrl('/user/redeemCode')}>I Received A Code From A Business</IonButton>
        </IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel>
          <IonButton color='danger' size={'default'} expand={'block'} onClick={() => signOut && signOut(history, user.userId, tus)}>Logout</IonButton>
        </IonLabel>
      </IonItem>
    </IonList>
  </>);
}
