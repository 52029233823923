import { UserResource } from '../../models/user';
import { useAppContext } from '../../libs/context';
import { useFetcher } from 'rest-hooks';
import { useHistory } from 'react-router';
import { schemas } from 'sprancer-shared';
import { Form, Formik, FormikHelpers } from 'formik';
import { reportException } from '../../libs/errors';
import { SaveButton } from '../../components/FormButtons';
import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonHeader,
  IonRow,
  IonSpinner,
  IonText, IonTitle, IonToolbar
} from '@ionic/react';
import { NoAuthLayoutPage } from '../../containers/NoAuthLayout';

export default function UserProfileCreate () {
  return (
    <NoAuthLayoutPage
      header={<Header/>}
      content={
        <IonGrid>
          <IonRow class="ion-align-items-center" style={{ minHeight: '90vh' }}>
            <IonCol size={'12'} size-md={'8'} offset-md={'2'} size-xl={'6'} offset-xl={'3'}>
              <Content />
            </IonCol>
          </IonRow>
        </IonGrid>
      }
    />);
}

function Header () {
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>User Profile Create</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
}

function Content () {
  const history = useHistory();
  const { signOut } = useAppContext();

  const createUser = useFetcher(UserResource.createCognitoUserShape());
  const [response, setResponse] = useState({ canConvert: false, error: '' });

  async function handleUserCreate (values: schemas.CognitoUserCreateType, actions?: FormikHelpers<schemas.CognitoUserCreateType>) {
    try {
      await createUser({}, values);
      history.replace('/user/home');
    } catch (e) {
      actions && actions.setSubmitting(false);
      if (e.status === 409) {
        setResponse({ canConvert: true, error: e.message });
      } else {
        setResponse({ canConvert: false, error: e.message });
        // Failed to create the user.  Not much we can do now, just report the exception;
        reportException(e, 'createUser failed in UserProfileCreate Content handleUserCreate');
      }
    }
  }

  useEffect(() => {
    // We have an authenticated cognito session with no user on the backed.  Create the backend user now.
    handleUserCreate({ convertTokenUser: false }).catch(e =>
      reportException(e, 'handleUserCreate failed in UserProfileCreate Content useEffect')
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues: schemas.CognitoUserCreateType = { convertTokenUser: true };

  return (
    <IonCard size-md={50}>
      <IonCardContent>
        { response.canConvert
          ? <Formik initialValues={initialValues} onSubmit={handleUserCreate} >
            {({ isSubmitting }) => (
              <Form>
                <h1 className={'text-center'}>Welcome Back!</h1>
                <p>You&apos;ve already connected to sprancer.  Continuing will protect your acccount with the entered password.</p>
                <div className={'text-center mb-2'}>
                  <SaveButton
                    size={'large'}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    text={<>Continue</>}
                    loadingText='Continuing…'
                  />
                </div>
                <div className={'text-center'}><p>or</p></div>
                <div className={'text-center'}>
                  <IonButton color={'danger'} onClick={() => signOut && signOut(history)} >Logout</IonButton>
                </div>
              </Form>
            )}
          </Formik>
          : !response.error
              ? <>
              <h1 className={'text-center'}>Welcome!</h1>
              <p>Creating profile...</p>
              <div className={'text-center'}><IonSpinner color='secondary'/></div>
            </>
              : <>
              <p className='ion-padding-bottom'><IonText color='danger'>{response.error}</IonText></p>
              <p>An error occurred while creating your profile.  Please contact support@sprancer.com for help or <a href='/'>try again</a>.</p>
            </>
        }
      </IonCardContent>
    </IonCard>
  );
}
