import API from '@aws-amplify/api';
import { AbstractInstanceType, SimpleResource } from 'rest-hooks';
import { constants } from 'sprancer-shared';
import { Method } from 'rest-hooks/lib/types';

// for now this is just a wrapper around the amplify API so we can mock it properly.
function del (...splat: Parameters<typeof API.del>) { return API.del(...splat); }
function get (...splat: Parameters<typeof API.get>) { return API.get(...splat); }
function patch (...splat: Parameters<typeof API.patch>) { return API.patch(...splat); }
function post (...splat: Parameters<typeof API.post>) { return API.post(...splat); }
function put (...splat: Parameters<typeof API.put>) { return API.put(...splat); }

const APIWrapper = {
  del: del,
  get: get,
  patch: patch,
  post: post,
  put: put
};

// Remove the token user secret, if present, from the url.
function extractTokenUserSecret (url: string): [string, string | null] {
  const searchStart = url.indexOf('?') + 1;
  if (searchStart > 0) {
    const tus = new URLSearchParams(url.substr(searchStart)).get('tus');
    if (tus) {
      return [url.replace(`tus=${tus}`, ''), tus];
    }
  }
  return [url, null];
}

export abstract class AmplifyResource extends SimpleResource {
  static fetchUrlOptionsPlugin: (url: string, options: RequestInit) => [string, RequestInit];
  /** Perform network request and resolve with json body */
  static fetch (method: Method, url: string, body?: Readonly<Record<string, unknown> | string>): Promise<unknown> {
    if (method === 'options') {
      throw new Error("Unsupported method 'options' for API.");
    }

    const [newUrl, tus] = extractTokenUserSecret(url);
    if (tus) {
      url = newUrl.replace('/', '/token'); // prepend 'token' to the resource name in the url.
    }

    let options = {
      ...body && { body: body },
      ...tus && { headers: { 'x-token-user-secret': tus } }
    };

    if (this.fetchUrlOptionsPlugin) {
      [url, options] = this.fetchUrlOptionsPlugin(url, options);
    }

    return APIWrapper[method === 'delete' ? 'del' : method](constants.API_NAME, url, options).catch(e => {
      if (e.response && !e.status) {
        e.status = e.response.status;
      }
      throw e;
    });
  }
}

export abstract class BaseBusinessResource extends AmplifyResource {
  /**
   * Get the url for a Resource
   */
  static url<T extends typeof SimpleResource> (
    this: T,
    urlParams: { businessId: string } & Partial<AbstractInstanceType<T>>
  ): string {
    if (!urlParams || !urlParams.businessId) {
      throw new Error('Business resources require businessId to retrieve');
    }

    const pk = this.pk(urlParams);
    const { businessId } = urlParams;

    if (pk !== undefined) {
      if (this.urlRoot.endsWith('/')) {
        return `${this.urlRoot}${businessId}/${pk}`;
      }

      return `${this.urlRoot}/${businessId}/${pk}`;
    }
    return `${this.urlRoot}/${businessId}`;
  }

  /**
   * Get the url for many Resources
   */
  static listUrl (searchParams: { businessId: string }): string {
    if (!searchParams || !searchParams.businessId) {
      throw new Error('Business resources require businessId to retrieve');
    }
    const { businessId, ...realSearchParams } = searchParams;
    const params = new URLSearchParams(realSearchParams as Record<string, string>);
    // this is essential for consistent url strings
    params.sort();
    if (this.urlRoot.endsWith('/')) {
      return `${this.urlRoot}${businessId}?${params.toString()}`;
    }
    return `${this.urlRoot}/${businessId}?${params.toString()}`;
  }
}

export abstract class BaseConnectionResource extends AmplifyResource {
  /**
   * Get the url for a Resource
   */
  static url<T extends typeof SimpleResource> (
    this: T,
    urlParams: { connectionId: string, tus?: string } & Partial<AbstractInstanceType<T>>
  ): string {
    if (!urlParams || !urlParams.connectionId) {
      throw new Error('Connection resources require connectionId to retrieve');
    }

    const pk = this.pk(urlParams);
    const { connectionId, tus } = urlParams;
    const tusParams = new URLSearchParams({ ...tus && { tus } });

    if (pk !== undefined) {
      if (this.urlRoot.endsWith('/')) {
        return `${this.urlRoot}${connectionId}/${pk}?${tusParams}`;
      }

      return `${this.urlRoot}/${connectionId}/${pk}?${tusParams}`;
    }
    return `${this.urlRoot}/${connectionId}?${tusParams}`;
  }

  /**
   * Get the url for many Resources
   */
  static listUrl (searchParams: { connectionId: string }): string {
    if (!searchParams || !searchParams.connectionId) {
      throw new Error('Connection resources require connectionId to retrieve');
    }
    const { connectionId, ...realSearchParams } = searchParams;
    const params = new URLSearchParams(realSearchParams as Record<string, string>);
    // this is essential for consistent url strings
    params.sort();
    if (this.urlRoot.endsWith('/')) {
      return `${this.urlRoot}${connectionId}?${params.toString()}`;
    }
    return `${this.urlRoot}/${connectionId}?${params.toString()}`;
  }
}
