import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Auth from '../../libs/auth';
import { Form, Formik, FormikHelpers } from 'formik';
import { SaveButton } from '../../components/FormButtons';
import { useAppContext } from '../../libs/context';
import * as yup from 'yup';
import { constants } from 'sprancer-shared';
import { reportException } from '../../libs/errors';
import { EmailFormLabelIcon, PasswordFormLabelIcon } from '../../libs/icons';
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent, IonCol, IonGrid, IonHeader, IonRouterLink, IonRow, IonTitle, IonToolbar
} from '@ionic/react';
import { IonFormikInput, UnexpectedFormErrors } from '../../components/Forms';
import { SignupConfirmationForm } from './SignupConfirmationForm';
import { NoAuthLayoutPage } from '../../containers/NoAuthLayout';
import { ActivationWelcome } from '../../components/Activations';

const LoginSchema = yup.object({
  email: yup.string().email('Must be an email address').required('Email is required').default(''),
  password: yup.string().required('Please enter your password').default('')
}).required();

type ConfirmationStatus = {email: string, password: string, resultStatus?: string};

function LoginForm ({ confirmationStatus, setConfirmationStatus }:
  { confirmationStatus: ConfirmationStatus | null,
    setConfirmationStatus: (u: ConfirmationStatus) => void}) {
  const history = useHistory<{email?: string, activationId?: string }>();
  const email = history.location.state?.email;
  const activationId = history.location.state?.activationId;

  const { signInAuthenticated } = useAppContext();

  async function handleSubmit (values: { email: string; password: string; }, actions: FormikHelpers<{ email: string; password: string; }>) {
    try {
      await Auth.signIn(values.email, values.password);
      actions.setSubmitting(false);
      actions.setStatus('');
      signInAuthenticated();
      if (activationId) {
        history.push(`/user/activations/${activationId}`);
      } else {
        history.push('/');
      }
    } catch (e) {
      actions.setSubmitting(false);
      reportException(e, 'handleSubmit failed in Login LoginForm');
      if (e instanceof Auth.IncorrectPasswordAuthError) {
        actions.setStatus(<>Incorrect password. Re-enter or <Link to='/login/forgot'>reset</Link> your password.</>);
      } else if (e instanceof Auth.UserDisabledAuthError) {
        actions.setStatus(<>Your account has been disabled. Please contact {constants.SUPPORT_EMAIL} for more information.</>);
      } else if (e instanceof Auth.UserNotConfirmedAuthError) {
        setConfirmationStatus(values);
      } else if (e instanceof Auth.UserNotFoundAuthError) {
        actions.setStatus(<>Your email is not registered. Please <Link to='/login/create'>create an account</Link>.</>);
      } else {
        actions.setStatus(e.message);
      }
    }
  }

  const initialValues = LoginSchema.default();
  initialValues.email = confirmationStatus?.email || email || '';

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={LoginSchema}
      initialStatus={confirmationStatus && confirmationStatus.resultStatus}
    >
      {({ isSubmitting, dirty }) => {
        return (
          <Form>
            <UnexpectedFormErrors expectedErrors={['email', 'password']}/>
            <p className='ion-padding-bottom'>Sign in to { activationId ? 'connect' : 'my account' }</p>
            <div className='ion-padding-bottom'>
              <IonFormikInput name='email' label={<EmailFormLabelIcon />} type='text' placeholder='Email' />
              <IonFormikInput name='password' label={<PasswordFormLabelIcon />} type='password' placeholder='Password'/>
              <div className="ion-text-end" >
                <IonRouterLink routerLink='/login/forgot'><small>Forgot password?</small></IonRouterLink>
              </div>
            </div>
            <SaveButton
              expand={'block'}
              disabled={isSubmitting || !dirty}
              isLoading={isSubmitting}
              text={<div>Login</div>}
              loadingText='Logging in…'
            />
            <div className="ion-text-center ion-padding-top" >
              <IonRouterLink routerLink='/login/create'>Create Account</IonRouterLink>
            </div>
          </Form>);
      }}
    </Formik>
  );
}

export default function Login () {
  return (
    <NoAuthLayoutPage
      header={<Header/>}
      content={
        <IonGrid>
          <IonRow class="ion-align-items-center" style={{ minHeight: '90vh' }}>
            <IonCol size={'12'} size-md={'8'} offset-md={'2'} size-xl={'6'} offset-xl={'3'}>
              <Content />
            </IonCol>
          </IonRow>
        </IonGrid>
      }
    />);
}

function Header () {
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>Login</IonTitle>
        <IonButtons slot="start">
          <IonBackButton defaultHref={'/login/welcome'}/>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
}

function Content () {
  const history = useHistory<{activationId?: string}>();
  const activationId = history.location.state?.activationId;

  const [confirmationStatus, setConfirmationStatus] = useState<ConfirmationStatus | null>(null);

  return (<>
    { activationId && <ActivationWelcome activationId={activationId} /> }
    <IonCard size-md={50}>
      <IonCardContent>
        { confirmationStatus === null || confirmationStatus.resultStatus
          ? <LoginForm confirmationStatus={confirmationStatus} setConfirmationStatus={setConfirmationStatus}/>
          : <SignupConfirmationForm email={confirmationStatus.email} onConfirmationSuccess={async () => {
            setConfirmationStatus({ ...confirmationStatus, resultStatus: 'Email address confirmed! Please login.' });
          }}/>
        }
      </IonCardContent>
    </IonCard>
  </>);
}
