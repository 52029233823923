import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Auth from '../../libs/auth';
import { Form, Formik, FormikHelpers } from 'formik';
import { SaveButton } from '../../components/FormButtons';
import { useAppContext } from '../../libs/context';
import * as yup from 'yup';
import { reportException } from '../../libs/errors';
import { EmailFormLabelIcon, PasswordFormLabelIcon } from '../../libs/icons';
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent, IonCol, IonGrid, IonHeader, IonRow, IonText, IonTitle, IonToolbar
} from '@ionic/react';
import { IonFormikInput, UnexpectedFormErrors } from '../../components/Forms';
import { SignupConfirmationForm } from './SignupConfirmationForm';
import { NoAuthLayoutPage } from '../../containers/NoAuthLayout';

const CreateAccountSchema = yup.object().shape({
  email: yup.string().email('Must be an email address').required('Email is required').default(''),
  password: yup.string()
    .required('Please enter your password')
    .matches(Auth.PASSWORD_REGEX, { message: Auth.passwordMessage }).default(''),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Passwords must match').defined().default('')
});

type NewUser = {email: string, password: string};

function CreateAccountForm ({ setNewUser }: {setNewUser: (u: NewUser) => void}) {
  const history = useHistory<{businessPasswordRequired?: boolean, email?: string}>();
  const email = history.location.state?.email;

  async function handleSubmit (
    values: { email: string; password: string; confirmPassword: string},
    actions: FormikHelpers<{ email: string; password: string; confirmPassword: string}>) {
    try {
      await Auth.signUp(values.email, values.password);
      actions.setSubmitting(false);
      setNewUser({ email: values.email, password: values.password });
    } catch (e) {
      actions.setSubmitting(false);
      reportException(e, 'handleSubmit failed in CreateAccount CreateAccountForm');

      if (e instanceof Auth.UsernameExistsAuthError) {
        actions.setStatus(<>Your email is already registered. Please <Link to='/login/password'>login</Link> or <Link to='/login/forgot'>reset your password</Link>.</>);
      } else {
        actions.setStatus(e.message);
      }
    }
  }

  const initialValues = CreateAccountSchema.required().default();
  initialValues.email = email || '';

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={CreateAccountSchema}
    >
      {({ isSubmitting, dirty }) => (
        <Form>
          <UnexpectedFormErrors expectedErrors={['email', 'password', 'confirmPassword']}/>
          {
            // Show the explanation if the user arrived here after trying to use /users/newBusiness from a tus account
            history.location.state?.businessPasswordRequired
              ? <IonText color='warning'>
              <h2 className={'text-muted text-center px-1 text-warning'}>Password Required</h2>
              <p className={''}><strong>Create a password based account before creating your business.</strong></p>
              <p>Use the same email you used when you joined sprancer.</p>
            </IonText>
              : <p className='ion-padding-bottom'>Create my account</p>
          }
          <div className='ion-padding-bottom'>
            <IonFormikInput name='email' label={<EmailFormLabelIcon />} type='text' placeholder='Email' />
            <IonFormikInput name='password' label={<PasswordFormLabelIcon />} type='password' placeholder='Password'/>
            <IonFormikInput name='confirmPassword' label={<PasswordFormLabelIcon />} type='password' placeholder='Repeat password'/>
          </div>
          <SaveButton
            expand={'block'}
            disabled={isSubmitting || !dirty}
            isLoading={isSubmitting}
            text={<div>Create Account</div>}
            loadingText='Creating…'
          />
        </Form>
      )}
    </Formik>
  );
}

export default function CreateAccount () {
  return (
    <NoAuthLayoutPage
      header={<Header/>}
      content={
        <IonGrid>
          <IonRow class="ion-align-items-center" style={{ minHeight: '90vh' }}>
            <IonCol size={'12'} size-md={'8'} offset-md={'2'} size-xl={'6'} offset-xl={'3'}>
              <Content />
            </IonCol>
          </IonRow>
        </IonGrid>
      }
    />);
}

function Header () {
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>Create Account</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref={'/login/welcome'}/>
          </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
}

function Content () {
  const history = useHistory();
  const { signInAuthenticated } = useAppContext();

  const [newUser, setNewUser] = useState<NewUser | null>(null);

  return (
    <IonCard size-md={50}>
      <IonCardContent>
        { newUser === null
          ? <CreateAccountForm setNewUser={setNewUser}/>
          : <SignupConfirmationForm email={newUser.email} onConfirmationSuccess={async () => {
            await Auth.signIn(newUser.email, newUser.password);
            signInAuthenticated();
            history.push('/');
          }}/>
        }
      </IonCardContent>
    </IonCard>
  );
}
