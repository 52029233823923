import { BaseConnectionResource } from './api';
import { arrays, channels, schemas } from 'sprancer-shared';
import { AbstractInstanceType, Resource } from 'rest-hooks';
import { format, parseISO } from 'date-fns';
import { tusifyUrl } from '../libs/tokenUserAuth';
import { ConnectionResource } from './connection';

export class ConnectionThread {
  firstMsg: ConnectionMessageResource;
  lastMsg: ConnectionMessageResource;
  sortedMsgsByChannel: Map<string, ConnectionMessageResource[]>;
  msgCount = 0;

  constructor (firstMsg: ConnectionMessageResource, sortedMsgsByChannel: Map<string, ConnectionMessageResource[]>) {
    this.firstMsg = firstMsg;
    this.sortedMsgsByChannel = sortedMsgsByChannel;
    const lastThread: ConnectionMessageResource[] = this.sortedMsgsByChannel.values().next().value;
    this.lastMsg = lastThread[0];

    this.sortedMsgsByChannel.forEach(msgList => { this.msgCount += msgList.length; });
  }

  threadId (): string {
    return this.firstMsg.threadId;
  }

  threadGroupIds (): string[] {
    return this.firstMsg.groupIds;
  }

  dateRange (): string {
    if (this.firstMsg.formattedDate() !== this.lastMsg.formattedDate()) {
      return (`${this.firstMsg.formattedDate()} - ${this.lastMsg.formattedDate()}`);
    } else {
      return (this.firstMsg.formattedDate());
    }
  }

  isDirect (): boolean {
    return channels.isDirectThreadId(this.threadId());
  }

  sortedMessages (): ConnectionMessageResource[] {
    const allMessages = Array.from(this.sortedMsgsByChannel.values()).flat();
    arrays.sortBy<ConnectionMessageResource>(allMessages, 'createdAt', true);
    return allMessages;
  }
}

export interface ConnectionMessageResource extends schemas.ConnectionMessageType {}
export class ConnectionMessageResource extends BaseConnectionResource {
  constructor (init?: schemas.ConnectionMessageCreateType) {
    super();
    Object.assign(this, init);
  }

  static urlRoot = '/connectionmessages';

  pk () {
    return this.id;
  }

  //
  // Helpers
  //

  static directThreadId (connectionId: string) {
    return channels.genDirectThreadId(ConnectionResource.calcCustomerIdFromConnectionId(connectionId));
  }

  formattedDate (): string {
    return format(parseISO(this.createdAt), 'MMMM do');
  }

  fromId (): string {
    return this.createdById;
  }

  fromMe (customerOrBusinessId: string): boolean {
    return this.createdById === customerOrBusinessId;
  }

  /**
   * Partitions the messages into threads.  The threads are sorted by activity (most recent first) and messages within
   * each thread are partioned by channel.  Channels are sorted by activity (again most recent first) and messages within
   * each channel are sorted most recent last.
   * @param allMessages
   * @returns threadsById a map of threadId to ConnectionThread
   */
  static partitionMessagesByThread (allMessages: ConnectionMessageResource[]): { threadsById: Map<string, ConnectionThread> } {
    // sort all messages into descending order.  This means the first message for a thread will be most recently active
    // and the last message for a thread will be the first message in that thread.
    arrays.sortBy<ConnectionMessageResource>(allMessages, 'createdAt', false);

    const threadDataById: Map<string, { firstMsg: ConnectionMessageResource, channelMsgs: Map<string, ConnectionMessageResource[]> }> = new Map();
    // first group all the messages into non-replies and replies by original message
    for (const m of allMessages) {
      const thread = threadDataById.get(m.threadId);
      if (!thread) {
        // first message for this thread, just create it from scratch.
        threadDataById.set(m.threadId, { firstMsg: m, channelMsgs: new Map([[m.channelId, [m]]]) });
      } else {
        // we're iterating in descending order so this message is the earliest we have seen for this thread
        thread.firstMsg = m;

        const channelMsgs = thread.channelMsgs.get(m.channelId);
        if (!channelMsgs) {
          // first message for this channel, add a new list to the thread's list of channels
          thread.channelMsgs.set(m.channelId, [m]);
        } else {
          channelMsgs.push(m);
        }
      }
    }

    const threadsById = new Map<string, ConnectionThread>();
    threadDataById.forEach((data) => {
      if (channels.isDirectChannelId(data.firstMsg.channelId) && !channels.isDirectThreadId(data.firstMsg.threadId)) {
        // This is a non-direct thread but the first message is direct.  This means it is a post where the first message
        // has been deleted.  Don't add it to the list of threads, just treat the whole thread as deleted.
        return;
      }

      threadsById.set(data.firstMsg.threadId, new ConnectionThread(data.firstMsg, data.channelMsgs));
    });

    return { threadsById: threadsById };
  }

  static customExpiryListShape<T extends typeof BaseConnectionResource> (this: T, { dataExpiryLength }: { dataExpiryLength: number }) {
    return {
      ...this.listShape(),
      options: { ...this.listShape().options, dataExpiryLength }
    };
  }

  //
  // Custom route stuff
  //

  static createReactionShape<T extends typeof Resource> (this: T) {
    return {
      ...this.createShape(),
      getFetchKey: (params: Readonly<Record<string, string>>) => {
        return tusifyUrl(`POST /connectionmessages/${params.connectionId}/${params.id}/reactions/${params.reactionId}`, params.tus);
      },
      fetch: (
        params: Readonly<Record<string, string>>,
        body: Partial<AbstractInstanceType<T>>
      ) => {
        return this.fetch('post', tusifyUrl(`/connectionmessages/${params.connectionId}/${params.id}/reactions/${params.reactionId}`, params.tus), body);
      }
    };
  }

  static deleteReactionShape<T extends typeof Resource> (this: T) {
    return {
      ...this.createShape(),
      getFetchKey: (params: Readonly<Record<string, string>>) => {
        return tusifyUrl(`DELETE /connectionmessages/${params.connectionId}/${params.id}/reactions/${params.reactionId}`, params.tus);
      },
      fetch: (
        params: Readonly<Record<string, string>>,
        body: Partial<AbstractInstanceType<T>>
      ) => {
        return this.fetch('delete', tusifyUrl(`/connectionmessages/${params.connectionId}/${params.id}/reactions/${params.reactionId}`, params.tus), body);
      }
    };
  }
}
