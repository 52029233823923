import { Redirect, Route } from 'react-router';
import React from 'react';
import UserNotificationsOverview from './UserNotificationsOverview';
import UserNotificationsEdit from './UserNotificationsEdit';
import { useTokenUserSecret } from '../../libs/tokenUserAuth';
import { Switch } from 'react-router-dom';
import { IonRouterOutletWrapper } from '../../components/RouterOutlet';

export default function UserNotifications () {
  const { tusifyUrl } = useTokenUserSecret();

  // IonRouterOutletWrapper for an explanation of this nonsense.
  const routes = [
    { path: '/user/notifications', name: 'Notifications', component: <UserNotificationsOverview /> },
    { path: '/user/notifications/edit', name: 'Edit Notifications', component: <UserNotificationsEdit /> }
  ];

  return (
    <Switch>
      <Route exact path={routes[0].path} name={routes[0].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[1].path} name={routes[1].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Redirect to={tusifyUrl('/user/notifications')} />
    </Switch>
  );
}
