import React, { Component, ErrorInfo } from 'react';
import ActivationLinkExpired from './ActivationLinkExpired';

export default class ActivationErrorBoundary extends Component {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public state: { error: any } = {
    error: null
  };

  public static getDerivedStateFromError (error: Error) {
    return { error };
  }

  public componentDidCatch (error: Error, info: ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, info);
  }

  public render () {
    if (this.state.error?.status === 404 || this.state.error?.status === 410) {
      return (<ActivationLinkExpired />);
    }

    return this.props.children;
  }
}
