import { schemas } from 'sprancer-shared';
import { AmplifyResource } from './api';
import { AbstractInstanceType, SimpleResource } from 'rest-hooks';
import { format, isThisYear, parseISO } from 'date-fns';

export interface UserPushDeviceResource extends schemas.UserPushDeviceType {}
export class UserPushDeviceResource extends AmplifyResource {
  constructor (init?: schemas.UserPushDeviceType) {
    super();
    Object.assign(this, init);
  }

  formattedCreatedAt () {
    const d = parseISO(this.createdAt);
    return isThisYear(d) ? format(d, 'MMMM do') : format(d, 'MMMM do yyyy');
  }

  nameWithDate () {
    return `${this.deviceName} registered ${this.formattedCreatedAt()}`;
  }

  pk () {
    return this.token;
  }
  //
  // Tus support
  //

  static url<T extends typeof SimpleResource> (
    this: T,
    urlParams: { userId: string, tus?: string } & Partial<AbstractInstanceType<T>>
  ): string {
    const pk = this.pk(urlParams);
    const { userId, tus } = urlParams;
    const tusParams = new URLSearchParams({ ...tus && { tus } });

    if (pk !== undefined) {
      return `/users/${userId}/pushdevices/${pk}?${tusParams}`;
    }
    return `/users/${userId}/pushdevices/?${tusParams}`;
  }

  static listUrl (searchParams: { userId: string }): string {
    if (!searchParams || !searchParams.userId) {
      throw new Error('Connection resources require connectionId to retrieve');
    }
    const { userId, ...realSearchParams } = searchParams;
    const params = new URLSearchParams(realSearchParams as Record<string, string>);
    // this is essential for consistent url strings
    params.sort();
    return `/users/${userId}/pushdevices?${params.toString()}`;
  }
}
