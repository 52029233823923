import { useContext, createContext } from 'react';
import { History } from 'history';
import { FontSize } from './fontSize';

type AppContextType = {
  authenticated: boolean;
  signInAuthenticated: () => void;
  storedTus: string | undefined;
  setStoredTus: (tus: string | undefined) => void;
  fontSize: FontSize;
  appIsActive: boolean;
  storeAndSetFontSize: (fontSize: FontSize) => Promise<void>;
  signOut: ((history: History, userId?: string, tus?: string) => void) | undefined
};

export const AppContext = createContext<AppContextType>({
  authenticated: false,
  signInAuthenticated: () => { throw new Error('setAuthenticated Not implemented'); },
  storedTus: undefined,
  setStoredTus: () => { throw new Error('setStoredTus Not implemented'); },
  fontSize: '',
  appIsActive: true,
  storeAndSetFontSize: async () => {
    // do nothing
  },
  signOut: undefined
});

export function useAppContext () {
  return useContext(AppContext);
}
