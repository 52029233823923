import { isPlatform } from '@ionic/react';

const dev = {
  activationBaseUrl: isPlatform('hybrid') ? 'http://localhost:8100' : window.location.origin,
  apiGateway: {
    REGION: 'us-east-2',
    URL: 'https://xzxfi3la60.execute-api.us-east-2.amazonaws.com/dev'
  },
  cognito: {
    REGION: 'us-east-2',
    USER_POOL_ID: 'us-east-2_Qny8hevfs',
    APP_CLIENT_ID: 'c7ja016cb3knmbak86cbiak4s',
    IDENTITY_POOL_ID: 'us-east-2:29d97ff5-56fb-46a5-9d1d-682bd50845fb'
  },
  sentryio: {
    dsn: ''
  },
  s3: {
    REGION: 'us-east-2',
    BUCKET: 'sprancer-api-dev-message-images'
  }
};

const prod = {
  activationBaseUrl: 'https://app.sprancer.com',
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://ngjdb5b7c9.execute-api.us-east-1.amazonaws.com/prod'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_vf6npAFo7',
    APP_CLIENT_ID: '2u1th83ss0omirdqehhelu3dn',
    IDENTITY_POOL_ID: 'us-east-1:16891257-4ef6-4618-9304-7f8cc658f2c9'
  },
  sentryio: {
    dsn: 'https://4ea2a9c29ca845dc842c051d8a60e82e@o351218.ingest.sentry.io/5286765'
  },
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'sprancer-api-prod-message-images'
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 500000,
  ...config
};
