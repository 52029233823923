import { ConnectionResource } from '../../models/connection';
import { ConnectionMessageResource, ConnectionThread } from '../../models/connectionMessage';
import { AvatarWithName } from '../../components/Avatars';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { MessageContentPreview } from '../../components/Messages';
import { AlertsContext } from '../../components/Alerts';
import {
  IonCol,
  IonRow,
  IonBadge,
  IonList,
  IonItem,
  IonToolbar, IonButtons, IonGrid, IonMenuButton, IonTitle, IonButton, IonModal
} from '@ionic/react';
import { UserLayoutPage } from '../../containers/UserLayout';
import { useTokenUserSecret } from '../../libs/tokenUserAuth';
import { ConnectionDropdown, UserDropdown } from '../../components/Dropdowns';
import { useHistory } from 'react-router';
import { Logo } from '../../components/Logos';
import { MissingSomethingWarning } from '../../components/Warnings';
import { GroupBadges } from '../../components/Groups';

type GroupInfo = {
  id: string;
  priv: boolean;
  name: string; color: string;
  hidden: boolean
}

export default function ConnectionMessageList ({ connection, threads }:
  {
    connection: ConnectionResource, threads: ConnectionThread[]
  }) {
  return (
    <UserLayoutPage
      header={<Header connection={connection} />}
      content={<Content connection={connection} threads={threads}/>}
      footer={<Footer connection={connection} />}
    />
  );
}

function Header ({ connection }: { connection: ConnectionResource }) {
  return (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton autoHide={true}/>
        </IonButtons>
        <IonTitle>{connection.businessName()}</IonTitle>
        <UserDropdown slot="end" />
      </IonToolbar>
    </>
  );
}

function Footer ({ connection }: { connection: ConnectionResource }) {
  const { tusifyUrl } = useTokenUserSecret();

  return (
    <>
      <IonToolbar>
        <div className={'d-flex flex-wrap-reverse justify-content-center'}>
          <Link className={'mx-1'} to={{
            pathname: `/connections/${connection.id}/messages/${ConnectionMessageResource.directThreadId(connection.id)}`,
            search: tusifyUrl(''), // use tusifyUrl with an empty string to create the "?tus=<tus>" search string if necessary
            state: { newMessage: true }
          }}>
            <IonButton color='primary'>
              Direct Message
            </IonButton>
          </Link>
        </div>
      </IonToolbar>
    </>
  );
}

function Content ({ connection, threads }:
  {
    connection: ConnectionResource, threads: ConnectionThread[]
  }) {
  // Show the welcome modal if the user has just activated this connection.
  const history = useHistory<{newActivation?: boolean, showHidden?: boolean}>();
  const newActivation = !!history.location.state?.newActivation;
  const showHidden = !!history.location.state?.showHidden;

  const { tusifyUrl } = useTokenUserSecret();

  const [showModal, setShowModal] = useState(newActivation);
  const [showHoursModal, setShowHoursModal] = useState(false);

  const groupInfoById = useMemo(() => {
    const hiddenGroupIds = new Set(connection.notifications?.groups.filter(g => g.freq === 'hide').map(g => g.id));
    const groupInfoById = new Map<string, GroupInfo>();
    connection.businessProfile.groups.forEach(g => {
      groupInfoById.set(g.id, { hidden: hiddenGroupIds.has(g.id), ...g });
    });
    return groupInfoById;
  }, [connection]);

  useEffect(() => {
    if (newActivation) {
      history.replace({ ...history.location, state: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newActivation]);

  let hiddenCount = 0;
  return (
    <>
      <IonRow className='mb-n5'>
        <ConnectionDropdown connection={connection} showHours={() => setShowHoursModal(true)} />
      </IonRow>
      <IonRow className='ion-justify-content-center my-2'>
        <Logo url={connection.businessProfile.logoImageUrl} size={'lg'}></Logo>
      </IonRow>
      <IonRow className='ion-justify-content-center'>
        <IonItem lines='none' button={true} detail={false} onClick={() => setShowModal(true)}>
          {connection.businessFullName()}
        </IonItem>
      </IonRow>
      <HoursModal connection={connection} show={showHoursModal} close={() => setShowHoursModal(false)}/>
      <WelcomeMessageModal connection={connection} show={showModal} close={() => setShowModal(false)}/>
      <IonList className='mb-2'>
        { threads.length === 0
          ? <MissingSomethingWarning
            warningIcon={false}
            title={'No Messages'} />
          : threads.map(thread => {
            const { isHidden, groups, hiddenGroups } = calcThreadInfo(connection, thread, groupInfoById);
            const groupsToDisplay = showHidden ? [...groups, ...hiddenGroups] : groups;

            if (!isHidden) {
              return <ConnectionThreadItem key={thread.threadId()} thread={thread} connectionId={connection.id} groups={groupsToDisplay}/>;
            } else {
              hiddenCount++;
              if (showHidden) {
                return <ConnectionThreadItem key={thread.threadId()} thread={thread} connectionId={connection.id} groups={groupsToDisplay}/>;
              } else {
                return <></>;
              }
            }
          })
        }
      </IonList>
      {hiddenCount > 0
        ? <Link to={{
          pathname: `/connections/${connection.id}/messages`,
          state: { showHidden: !showHidden },
          search: tusifyUrl('') // use tusifyUrl with an empty string to create the "?tus=<tus>" search string if necessary
        }}>
          <div className='small ion-text-center mb-2'>{
            showHidden
              ? `Stop showing ${hiddenCount} hidden message${hiddenCount > 1 ? 's' : ''}.`
              : `Show ${hiddenCount} hidden message${hiddenCount > 1 ? 's' : ''}.`
          }</div>
        </Link>
        : <div className='text-muted small ion-text-center mb-2'>Messages expire after 30 days.</div>
      }
    </>
  );
}

function ConnectionThreadItem ({ thread, connectionId, groups }: { thread: ConnectionThread; connectionId: string; groups: GroupInfo[] }) {
  const { tusifyUrl } = useTokenUserSecret();
  const { pathToAlertIds } = useContext(AlertsContext);
  const threadUrl = `/connections/${connectionId}/messages/${thread.threadId()}`;
  const alertCount = pathToAlertIds.get(threadUrl)?.length;

  return (
    <IonItem className='m-0' routerLink={tusifyUrl(`/connections/${connectionId}/messages/${thread.threadId()}`)} detail={true}
             button={true}>
      {alertCount && <IonBadge slot='end' color={'danger'}>{alertCount}</IonBadge>}
      <IonGrid className='ion-grid-padding'>
        <IonRow className='ion-justify-content-between'>
          <IonCol>
            <div className='small text-muted'>
              { thread.dateRange() }
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className='d-flex flex-wrap'>
            <GroupBadges groups={groups}/>
            {thread.isDirect() && <IonBadge color={'warning'}>direct</IonBadge>}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <MessageContentPreview message={ thread.isDirect() ? thread.lastMsg : thread.firstMsg } showImage={true}/>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
}

function WelcomeMessageModal ({ connection, show, close }: { connection: ConnectionResource, show: boolean; close: () => void }) {
  return (
    <IonModal isOpen={show} onDidDismiss={close} >
      <IonGrid className={'w-100'}>
        <IonRow><IonCol><p className={'text-muted small'}>You&apos;re connected with</p></IonCol></IonRow>
        { connection.businessProfile.logoImageUrl && <IonRow className='ion-justify-content-center'>
          <IonItem lines='none'>
            <Logo url={connection.businessProfile.logoImageUrl} size={'lg'}/>
          </IonItem>
        </IonRow> }
        <IonRow>
          <blockquote className="blockquote">
            <p className='text-preline'>{connection.businessProfile.welcomeMessage}</p>
            <footer className="d-flex ion-justify-content-center"><IonItem lines='none'><span className={'text-muted mr-1'}>&mdash;</span><AvatarWithName avatar={connection.businessProfile.avatar} name={connection.businessFullName()}/></IonItem></footer>
          </blockquote>
        </IonRow>
        <IonRow className='ion-justify-content-center'>
          <IonCol sizeXs={'10'}><IonButton expand={'block'} size='default' color="primary" onClick={close}>OK</IonButton></IonCol>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
}

function HoursModal ({ connection, show, close }: { connection: ConnectionResource, show: boolean; close: () => void }) {
  return (
    <IonModal isOpen={show} onDidDismiss={close} >
      <IonGrid className={'w-100'}>
        <IonRow><IonCol><p className={'text-muted small'}>Hours for</p></IonCol></IonRow>
        { connection.businessProfile.logoImageUrl && <IonRow className='ion-justify-content-center'>
          <IonItem lines='none'>
            <Logo url={connection.businessProfile.logoImageUrl} size={'lg'}/>
          </IonItem>
        </IonRow> }
        <IonRow>
          <blockquote className="blockquote">
            <p className='text-preline text-break'>{connection.businessProfile.hours}</p>
          </blockquote>
        </IonRow>
        <IonRow className='ion-justify-content-center'>
          <IonCol sizeXs={'10'}><IonButton expand={'block'} size='default' color="primary" onClick={close}>OK</IonButton></IonCol>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
}

function calcThreadInfo (connection: ConnectionResource, thread: ConnectionThread, groupInfoById: Map<string, GroupInfo>): {
  isHidden: boolean,
  groups: GroupInfo[],
  hiddenGroups: GroupInfo[]
} {
  const groups: GroupInfo[] = [];
  const hiddenGroups: GroupInfo[] = [];
  thread.threadGroupIds().forEach(groupId => {
    const group = groupInfoById.get(groupId);
    if (group) {
      if (group.hidden) {
        hiddenGroups.push(group);
      } else {
        groups.push(group);
      }
    }
  });

  if (thread.isDirect()) {
    if (connection.notifications?.direct === 'hide') {
      return { isHidden: true, groups, hiddenGroups };
    }
  } else if (groups.length === 0) {
    if (hiddenGroups.length > 0) {
      // This only has hidden groups, hide it.
      return { isHidden: true, groups, hiddenGroups };
    }
    if (connection.notifications?.other === 'hide') {
      // This belongs to no group and 'everything else' is hidden
      return { isHidden: true, groups, hiddenGroups };
    }
  }
  return { isHidden: false, groups, hiddenGroups };
}
