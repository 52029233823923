import { useHistory } from 'react-router';
import { useFetcher } from 'rest-hooks';
import { ActivationCodeResource } from '../../models/activation';
import { schemas } from 'sprancer-shared';
import { Form, Formik, FormikHelpers } from 'formik';
import { reportException } from '../../libs/errors';
import { IonActivationCodeInput, UnexpectedFormErrors } from '../../components/Forms';
import { SaveButton } from '../../components/FormButtons';
import React from 'react';
import { useTokenUserSecret } from '../../libs/tokenUserAuth';

export default function RedeemCodeForm () {
  const { tus, tusifyUrl } = useTokenUserSecret();

  const history = useHistory<{email?: string, alert?: string}>();

  const redeemCode = useFetcher(ActivationCodeResource.redeemCodeShape());

  async function handleSubmit (values: schemas.ActivationRedeemCodeType, actions: FormikHelpers<schemas.ActivationRedeemCodeType>) {
    try {
      values.code = values.code.replace(/ /g, '');
      const redeemedCode = await redeemCode({ ...tus && { tus } }, values) as schemas.ActivationCodeType;
      actions.setSubmitting(false);
      history.push(tusifyUrl(redeemedCode.path));
    } catch (e) {
      if (e.response?.status === 404) {
        actions.setStatus('Unknown code.');
      } else {
        reportException(e, 'handleSubmit failed in RedeemCodeForm RedeemCodeForm');
        actions.setStatus(e.message || e);
        actions.setSubmitting(false);
      }
    }
  }

  return (
    <Formik
        initialValues={schemas.ActivationRedeemCodeSchema.required().default()}
        onSubmit={handleSubmit}
        validationSchema={schemas.ActivationRedeemCodeSchema}
      >
      {({ isSubmitting, dirty }) => (
        <Form>
          <UnexpectedFormErrors expectedErrors={['code']}/>
          <div className='d-flex mb-2'>
            <div className={'mx-auto'} style={{ width: '15rem' }}>
              <IonActivationCodeInput name={'code'} type={'text'} />
            </div>
          </div>
          <div className='d-flex'>
            <div className={'mx-auto'} style={{ width: '15rem' }}>
              <SaveButton expand={'block'} size={'large'} text={'Connect'} loadingText={'Connecting…'} disabled={isSubmitting || !dirty} isLoading={isSubmitting}/>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
