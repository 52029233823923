import {
  IonBadge,
  IonButtons,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonNote,
  IonTitle,
  IonToolbar, isPlatform
} from '@ionic/react';
import React, { useContext } from 'react';
import { UserLayoutPage } from '../../containers/UserLayout';
import { useGetCurrentUser } from '../../models/user';
import { useTokenUserSecret } from '../../libs/tokenUserAuth';
import { AlertsContext } from '../../components/Alerts';
import * as lo from 'lodash';
import { UserDropdown } from '../../components/Dropdowns';
import { Logo } from '../../components/Logos';
import { AvatarWithName } from '../../components/Avatars';
import { useResource } from 'rest-hooks';
import { BusinessResource } from '../../models/business';
import { ConnectionResource } from '../../models/connection';
import { Link } from 'react-router-dom';
import { usePrefersDarkMode } from '../../libs/mediaQueries';
import NotFound404ErrorBoundary from '../Errors/NotFound404ErrorBoundary';
import { UserNewConnectionHowToContent } from './UserNewConnectionHowTo';
import { useBackground } from '../../libs/background';
import { constants, strings } from 'sprancer-shared';
import * as APP_STORE_BADGE from '../../assets/img/appstorebadge.png';
import * as GOOGLE_PLAY_BADGE from '../../assets/img/googleplaybadge.png';

export default function UserHome () {
  const { contentStyle } = useBackground();

  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
      // turn off the ionic shadow '--background' varible and use a css variable.  --background was flickering on ios.
      contentStyle={contentStyle}
    />
  );
}

function Header () {
  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonMenuButton autoHide={true}/>
      </IonButtons>
      <IonTitle>Home</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

const BUSINESS_PATH_REGEX = /\/businesses\/([^/]+)/;
const CONNECTION_PATH_REGEX = /\/connections\/([^/]+)/;

function calcConnectedSentence (bizCount: number, connCount: number): string {
  if (bizCount > 0 && connCount > 0) {
    return `You own ${pluralizeBusiness(bizCount)} and are connected to ${strings.wordenizeCount(connCount)} ${connCount > 1 ? 'others' : 'other'}.`;
  } else if (bizCount > 0) {
    return `You own ${pluralizeBusiness(bizCount)}`;
  } else if (connCount > 0) {
    return `You are connected to ${pluralizeBusiness(connCount)}.`;
  } else {
    return 'You are not connected to any businesses.';
  }
}

function pluralizeBusiness (count: number): string {
  return count === 1 ? 'one business' : `${strings.wordenizeCount(count)} businesses`;
}

function Content () {
  const { tusifyUrl } = useTokenUserSecret();
  const darkmode = usePrefersDarkMode();

  const user = useGetCurrentUser();

  const { pathToAlertIds } = useContext(AlertsContext);

  const sortedPerms = lo.sortBy(user?.permissions || [], ['name']);
  const [businessPerms, connectionPerms] = lo.partition(sortedPerms, p => p.path.startsWith('/businesses/'));

  if (sortedPerms.length === 0) {
    return <WelcomeContent />;
  }

  return (<>
    <IonItem color='transparent' lines='none'>
      <h4 className={'text-muted text-break'}>Hello {user.nickname},</h4>
    </IonItem>
    <IonItem color='transparent' lines='none' className={'mb-4'}>
      <IonLabel>
        <p className={'text-wrap text-muted'}>
          {`Sprancer is for messaging with local businesses. ${calcConnectedSentence(businessPerms.length, connectionPerms.length)}`}
        </p>
      </IonLabel>
    </IonItem>

    {businessPerms.length > 0 && <IonList className={'mb-5 py-0'}>
      <IonItem lines={'full'}>
        <IonNote className={'py-3'}> <strong>Message your customers</strong></IonNote>
      </IonItem>
      {businessPerms.map(p => {
        const businessId = BUSINESS_PATH_REGEX.exec(p.path)?.[1];
        return (businessId &&
          <NotFound404ErrorBoundary key={p.path} fallback={<IonItem lines='full'><IonNote className={'py-3'}><strong>{p.name}</strong> has been removed.</IonNote></IonItem>}>
            <BusinessCard businessId={businessId} pathToAlertIds={pathToAlertIds}/>
          </NotFound404ErrorBoundary>
        );
      })}
    </IonList>}

    {connectionPerms.length > 0 && <IonList className={'mb-5 py-0'}>
      <IonItem lines={'full'}>
        <IonNote className={'py-3'}> <strong>Choose a business to see messages</strong></IonNote>
      </IonItem>
      {connectionPerms.map(p => {
        const connectionId = CONNECTION_PATH_REGEX.exec(p.path)?.[1];
        return (connectionId &&
          <NotFound404ErrorBoundary key={p.path} fallback={<IonItem lines='full'><IonLabel className={'py-3'}><strong>{p.name}</strong> has been removed.</IonLabel></IonItem>}>
            <ConnectionCard connectionId={connectionId} pathToAlertIds={pathToAlertIds}/>
          </NotFound404ErrorBoundary>
        );
      })}
    </IonList>}

    <AppBadges />

    <div className={`text-center mb-4 p-3 ${darkmode ? 'text-muted' : 'bg-white-half-transparent'}`}>
      <Link to={tusifyUrl('/user/newConnectionHowTo')}>How do I connect to another business?</Link>
    </div>
  </>);
}

function BusinessCard ({ businessId, pathToAlertIds }: { businessId: string, pathToAlertIds: Map<string, string[]> }) {
  const path = `/businesses/${businessId}`;
  const alertCount = pathToAlertIds.get(path)?.length;

  const business = useResource(BusinessResource.detailShape(), { id: businessId });
  if (!business) {
    return (<></>);
  }

  return (
    <IonItem className='' lines='none' routerLink={path} detail={true} button={true}>
      {alertCount && <IonBadge slot='end' color={'danger'}>{alertCount}</IonBadge>}
      {/* <IonLabel>{business.businessName} <IonBadge color={'warning'}>My Business</IonBadge></IonLabel> */}
      <div className={'py-3'}>
        <div className={'d-flex'}>
          <div className={'mr-1'}>
            {business.logoImageUrl
              ? <><Logo url={business.logoImageUrl} size={'md'}/></>
              : <AvatarWithName avatar={business.avatar} name={<>{business.fullName()}</>}/>}
          </div>
          <div><IonBadge color={'warning'}>My Business</IonBadge></div>
        </div>
      </div>
    </IonItem>
  );
}

function ConnectionCard ({
  connectionId,
  pathToAlertIds
}: { connectionId: string, pathToAlertIds: Map<string, string[]> }) {
  const { tus, tusifyUrl } = useTokenUserSecret();
  const path = `/connections/${connectionId}`;
  const alertCount = pathToAlertIds.get(path)?.length;

  const connection = useResource(ConnectionResource.detailShape(), { id: connectionId, ...tus && { tus } });
  if (!connection) {
    return (<></>);
  }

  return (
    <IonItem lines='full' routerLink={tusifyUrl(path)} detail={true} button={true}>
      { connection.businessProfile.logoImageUrl
        ? <>
          <div className='py-3' slot='start'><Logo url={connection.businessProfile.logoImageUrl} size={'md'}/></div>
          <div>{connection.businessFullName()}</div>
        </>
        : <AvatarWithName avatar={connection.businessProfile.avatar} name={connection.businessFullName()} />
      }

      { alertCount && <IonBadge slot='end' color={'danger'}>{alertCount}</IonBadge> }
    </IonItem>
  );
}

function WelcomeContent () {
  const user = useGetCurrentUser();

  return (<>
    <IonItem color='transparent' lines='none'>
      <h4 className={'text-muted'}>Hello {user.nickname},</h4>
    </IonItem>
    <IonItem color='transparent' lines='none' className={'mb-4'}>
      <IonLabel>
        <p className={'text-wrap text-muted'}>
          {`Sprancer is for messaging with local businesses. ${calcConnectedSentence(0, 0)}`}
        </p>
      </IonLabel>
    </IonItem>
    <UserNewConnectionHowToContent/>
  </>);
}

function AppBadges () {
  if (isPlatform('hybrid')) {
    return (<></>);
  }

  return (<div className={'mb-3'}>
    <p className={'ion-text-center text-muted'}>Sprancer works better on your phone!</p>
    <div className={'d-flex ion-justify-content-center'}>
      <a className='px-3' href={constants.SPRANCER_APP_STORE_URL}>
        <img src={APP_STORE_BADGE.default}
             alt="Download on the App Store"
             height={38}
        />
      </a>
      <a className='px-3' href={constants.SPRANCER_PLAY_STORE_URL}>
        <img src={GOOGLE_PLAY_BADGE.default}
             alt='Get it on Google Play'
             height={38}
        />
      </a>
    </div>
  </div>);
}
