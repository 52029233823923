import { AmplifyResource } from './api';
import { schemas } from 'sprancer-shared';
import { Resource } from 'rest-hooks';
import md5 from 'md5';

export interface ActivationResource extends schemas.ActivationType {}
export class ActivationResource extends AmplifyResource {
  pk () {
    return this.id;
  }

  businessFullName () {
    return this.businessProfile.contactName + ' @ ' + this.businessProfile.businessName;
  }

  static sendShape<T extends typeof Resource> (this: T) {
    return {
      ...this.createShape(),
      getFetchKey: ({ id }: { id: string }) => {
        return `/activations/${id}/send`;
      },
      fetch: ({ id }: { id: string }, body?: Readonly<schemas.ActivationSendType>) => {
        return this.fetch('post', `/activations/${id}/send`, body)
          .then(() => ({ id: '0' })); // rest-hooks demands an id in the response since this is derived from createShape
      }
    };
  }

  static saveActivationShape<T extends typeof Resource> (this: T) {
    return {
      ...this.createShape(),
      getFetchKey: ({ id }: { id: string }) => {
        return `/activations/${id}/save`;
      },
      fetch: (params: Record<string, string>, body?: Readonly<schemas.ActivationCreateType>) => {
        return this.fetch('post', '/activations', body)
          .then(() => ({ id: '0' })); // rest-hooks demands an id in the response since this is derived from createShape
      }
    };
  }

  static urlRoot = '/activations';
}

export function genSavedActivationId (email: string) {
  return schemas.SAVED_ACTIVATION_PREFIX + md5(email);
}

export interface ActivationCodeResource extends schemas.ActivationCodeType {}
export class ActivationCodeResource extends AmplifyResource {
  pk () {
    return this.id;
  }

  static redeemCodeShape<T extends typeof Resource> (this: T) {
    return {
      ...this.createShape(),
      fetch: (params: { tus?: string }, body?: Readonly<schemas.ActivationRedeemCodeType>) => {
        return this.fetch('post', `/activationcodes${params.tus ? '?tus=' + params.tus : ''}`, body);
      }
    };
  }

  static urlRoot = '/activationcodes';
}
