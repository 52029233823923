import * as yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';

import { SaveButton } from '../../components/FormButtons';
import React, { useState } from 'react';
import * as Auth from '../../libs/auth';
import { reportException } from '../../libs/errors';
import { Link, useHistory } from 'react-router-dom';
import { constants } from 'sprancer-shared';
import { useAppContext } from '../../libs/context';
import { EmailFormLabelIcon, PasswordFormLabelIcon } from '../../libs/icons';
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent, IonCol, IonGrid, IonHeader, IonRow, IonTitle, IonToolbar
} from '@ionic/react';
import { IonFormikInput, UnexpectedFormErrors } from '../../components/Forms';
import { NoAuthLayoutPage } from '../../containers/NoAuthLayout';

const ForgotPasswordSchema = yup.object({
  email: yup.string().email('Must be an email address').required('Email is required').default('')
});

type ForgotPasswordConfirmationValues = {
  email: string;
  confirmationCode: string;
  password: string;
  confirmPassword: string;
}

const ForgotPasswordConfirmationSchema = yup.object().shape({
  email: yup.string().email('Must be an email address').required('Email is required').default(''),
  confirmationCode: yup.string().defined().default(''),
  password: yup.string()
    .required('Please enter your password')
    .matches(Auth.PASSWORD_REGEX, { message: Auth.passwordMessage }).default(''),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Passwords must match').defined().default('')
});

function ForgotPasswordConfirmationForm ({ confirmationEmail }: { confirmationEmail?: string }) {
  const { signInAuthenticated } = useAppContext();
  const history = useHistory();

  async function handleSubmit (values: ForgotPasswordConfirmationValues, actions: FormikHelpers<ForgotPasswordConfirmationValues>) {
    try {
      await Auth.forgotPasswordSubmit(values.email, values.confirmationCode, values.password);
      await Auth.signIn(values.email, values.password);
      actions.setSubmitting(false);
      actions.setStatus('');
      signInAuthenticated();
      history.push('/');
    } catch (e) {
      actions.setSubmitting(false);
      reportException(e, 'handleSubmit failed in ForgotPassword ForgotPasswordConfirmationForm');

      if (e instanceof Auth.UserDisabledAuthError) {
        actions.setStatus(<>Your account has been disabled. Please contact {constants.SUPPORT_EMAIL} for more information.</>);
      } else if (e instanceof Auth.UserNotConfirmedAuthError) {
        actions.setStatus(<>Please <Link to='/login/password'>login</Link> to confirm your email address.</>);
      } else if (e instanceof Auth.UserNotFoundAuthError) {
        actions.setStatus(<>Your email is not registered. Please <Link to='/login/create'>create an account</Link>.</>);
      } else if (e instanceof Auth.CodeMismatchAuthError) {
        actions.setStatus(<>Invalid verification code provided, please try again.</>);
      } else {
        actions.setStatus(e.message);
      }
    }
  }

  const initialValues = ForgotPasswordConfirmationSchema.required().default();
  initialValues.email = confirmationEmail || '';

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ForgotPasswordConfirmationSchema}
    >
      {({ isSubmitting, dirty }) => (
        <Form>
          <UnexpectedFormErrors expectedErrors={['email', 'confirmationCode', 'password', 'confirmPassword']}/>
          <p className='ion-padding-bottom'>Please check your email for a confirmation code.</p>
          <div className='ion-padding-bottom'>
            <IonFormikInput name='email' label={<EmailFormLabelIcon />} type='text' placeholder='Email' />
            <IonFormikInput name='confirmationCode' label={<PasswordFormLabelIcon />} type='tel' placeholder='Confirmation Code'/>
            <IonFormikInput name='password' label={<PasswordFormLabelIcon />} type='password' placeholder='Password'/>
            <IonFormikInput name='confirmPassword' label={<PasswordFormLabelIcon />} type='password' placeholder='Repeat password'/>
          </div>
          <SaveButton
            expand={'block'}
            disabled={isSubmitting || !dirty}
            isLoading={isSubmitting}
            text={<div>Reset Password</div>}
            loadingText='Resetting…'
          />
        </Form>
      )}
    </Formik>
  );
}

function ForgotPasswordForm ({ setConfirmationEmail }: { setConfirmationEmail: React.Dispatch<React.SetStateAction<string | null>>}) {
  async function handleSubmit (values: { email: string }, actions: FormikHelpers<{ email: string }>) {
    try {
      await Auth.forgotPassword(values.email);

      actions.setSubmitting(false);
      setConfirmationEmail(values.email);
    } catch (e) {
      actions.setSubmitting(false);
      reportException(e, 'handleSubmit failed in ForgotPassword ForgotPasswordForm');

      if (e instanceof Auth.UserDisabledAuthError) {
        actions.setStatus(<>Your account has been disabled. Please contact {constants.SUPPORT_EMAIL} for more information.</>);
      } else if (e instanceof Auth.UserNotFoundAuthError) {
        actions.setStatus(<>Your email is not registered. Please <Link to='/login/create'>create an account</Link>.</>);
      } else {
        actions.setStatus(e.message);
      }
    }
  }

  const initialValues = ForgotPasswordSchema.required().default();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ForgotPasswordSchema}
    >
      {({ isSubmitting, dirty }) => (
        <Form>
          <UnexpectedFormErrors expectedErrors={['email']}/>
          <p className='ion-padding-bottom'>Enter your email address to receive a password reset code.</p>
          <div className='ion-padding-bottom'>
            <IonFormikInput name='email' label={<EmailFormLabelIcon />} type='text' placeholder='Email' />
          </div>
          <SaveButton
            expand={'block'}
            disabled={isSubmitting || !dirty}
            isLoading={isSubmitting}
            text={<div>Reset Password</div>}
            loadingText='Logging in…'
          />
        </Form>
      )}
    </Formik>
  );
}

export default function ForgotPassword () {
  return (
    <NoAuthLayoutPage
      header={<Header/>}
      content={
        <IonGrid>
          <IonRow class="ion-align-items-center" style={{ minHeight: '90vh' }}>
            <IonCol size={'12'} size-md={'8'} offset-md={'2'} size-xl={'6'} offset-xl={'3'}>
              <Content />
            </IonCol>
          </IonRow>
        </IonGrid>
      }
    />);
}

function Header () {
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>Forgot Password</IonTitle>
        <IonButtons slot="start">
          <IonBackButton defaultHref={'/login/welcome'}/>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
}

function Content () {
  const [confirmationEmail, setConfirmationEmail] = useState<string | null>(null);

  return (
    <IonCard size-md={50}>
      <IonCardContent>
        { confirmationEmail === null
          ? <ForgotPasswordForm setConfirmationEmail={setConfirmationEmail}/>
          : <ForgotPasswordConfirmationForm confirmationEmail={confirmationEmail} />
        }
      </IonCardContent>
    </IonCard>
  );
}
