import * as yup from 'yup';
import { UserLayoutPage } from '../../containers/UserLayout';
import { useTokenUserSecret } from '../../libs/tokenUserAuth';
import {
  IonBackButton,
  IonButtons,
  IonItem, IonItemDivider, IonLabel,
  IonList,
  IonTitle,
  IonToolbar, NavContext
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { schemas } from 'sprancer-shared';
import { useParams } from 'react-router';
import { useFetcher, useResource } from 'rest-hooks';
import { Form, Formik, FormikHelpers } from 'formik';
import { reportException } from '../../libs/errors';
import {
  NotificationFrequencyInput2,
  UnexpectedFormErrors
} from '../../components/Forms';
import { TextSaveButton } from '../../components/FormButtons';
import React, { useContext } from 'react';
import { ConnectionResource } from '../../models/connection';
import { FrequencyType } from 'sprancer-shared/dist/schemas';
import { useGetCurrentUser } from '../../models/user';

yup.setLocale({
  mixed: {
    required: 'required'
  }
});

export default function ConnectionNotifications () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
    />
  );
}

function Header () {
  const { connectionId } = useParams<{ connectionId: string }>();
  const { tusifyUrl } = useTokenUserSecret();

  return (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton text='Cancel' defaultHref={tusifyUrl(`/connections/${connectionId}/notifications`)}/>
        </IonButtons>
        <IonTitle>Notifications</IonTitle>
        <UserDropdown slot="end"/>
      </IonToolbar>
    </>
  );
}

type ConnectionNotificationsType = {
  direct: FrequencyType;
  other: FrequencyType;
  groups: Record<string, FrequencyType>;
}

function map5MinutesToInstant (freq: schemas.FrequencyType): schemas.FrequencyType {
  return freq === '5 minutes' ? 'instant' : freq;
}

function Content () {
  const { connectionId } = useParams<{ connectionId: string }>();
  const user = useGetCurrentUser();
  const { tus } = useTokenUserSecret();
  const connection = useResource(ConnectionResource.detailShape(), { id: connectionId, ...tus && { tus } });
  const update = useFetcher(ConnectionResource.updateShape());

  const { goBack } = useContext(NavContext);

  async function handleSubmit (values: ConnectionNotificationsType, actions: FormikHelpers<ConnectionNotificationsType>) {
    try {
      const { groups, ...rest } = values;
      const updateValues: schemas.ConnectionUpdateType = {
        notifications: {
          groups: connection.businessProfile.groups.map(g => ({ id: g.id, freq: groups[g.id] || schemas.DEFAULT_FREQUENCY })),
          ...rest
        }
      };

      await update({ id: connectionId, ...tus && { tus } }, updateValues);
      actions.setSubmitting(false);
      goBack(`/connections/${connectionId}`);
    } catch (e) {
      reportException(e, 'handleSubmit failed in UserNotificationsEdit Content');
      actions.setStatus(e.message || e);
      actions.setSubmitting(false);
    }
  }

  const initialGroups: Record<string, FrequencyType> = {};
  connection.businessProfile.groups.forEach(g => { initialGroups[g.id] = map5MinutesToInstant(user.defaultFrequency()); });
  connection.notifications?.groups.forEach(g => { initialGroups[g.id] = map5MinutesToInstant(g.freq); });
  const initialValues: ConnectionNotificationsType = {
    direct: map5MinutesToInstant(connection.notifications?.direct || user.defaultDirectMessageFrequency()),
    other: map5MinutesToInstant(connection.notifications?.other || user.defaultFrequency()),
    groups: initialGroups
  };

  return (
    <IonList>
      <Formik
        key={connectionId} // This seems to be required to prevent form state from being shared between different businesses
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty, values }) => {
          return (
            <Form>
              <UnexpectedFormErrors expectedErrors={[]}/>
              <IonItem lines={'none'}>
                <h3 className={'text-muted text-break'}>{connection.businessName()} Notification Settings</h3>
              </IonItem>
              <IonItem color='transparent' lines='none' className={'mb-4'}>
                <IonLabel>
                  <p className={'text-wrap text-muted'}>
                    Set how frequently you would like to be alerted when you receive messages from {connection.businessFullName()}.
                  </p>
                </IonLabel>
              </IonItem>
              <IonList className={'ion-padding-bottom'}>
                <IonItemDivider />
                <NotificationFrequencyInput2 name='direct' label={'Direct Messages'} />
                { connection.businessProfile.groups.length > 0 && <IonItemDivider /> }
                {
                  connection.businessProfile.groups.map(group => {
                    return <NotificationFrequencyInput2
                      key={group.id}
                      name={'groups.' + group.id}
                      label={<div className={values.groups[group.id] === 'hide' ? 'text-muted' : ''}>{group.name}</div>}
                    />;
                  })
                }
                { connection.businessProfile.groups.length > 0 && <IonItemDivider /> }
                <NotificationFrequencyInput2 name='other' label={'Everything Else'} />
              </IonList>
              <IonItem lines="none" className='text-center'>
                <IonLabel>
                  <TextSaveButton disabled={isSubmitting || !dirty} isLoading={isSubmitting} />
                </IonLabel>
              </IonItem>
            </Form>
          );
        }}
      </Formik>
    </IonList>
  );
}
