import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppUrlOpen, Plugins } from '@capacitor/core';
const { App: CapApp } = Plugins;

export default function AppUrlListener () {
  const history = useHistory();
  useEffect(() => {
    const handle = CapApp.addListener('appUrlOpen', (data: AppUrlOpen) => {
      // Example url: https://app.sprancer.com/user/current?tus=something
      const url = new URL(data.url);
      const path = url.pathname + url.search;
      if (path) {
        history.push(path);
      }
      // If no match, do nothing - let regular routing logic take over
    });

    // clean up the handler before rendering the next effect
    return handle.remove;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return null;
}
