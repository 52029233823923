import { AmplifyResource } from './api';
import { schemas } from 'sprancer-shared';
import { Resource } from 'rest-hooks';
import { format, isThisYear, parseISO } from 'date-fns';

export interface ConnectionResource extends schemas.ConnectionType {}
export class ConnectionResource extends AmplifyResource {
  constructor (init?: schemas.ConnectionType) {
    super();
    Object.assign(this, init);
  }

  pk () {
    return this.id;
  }

  static calcCustomerIdFromConnectionId (connectionId: string) {
    const split = connectionId.split('Z');
    return split[split.length - 1];
  }

  customerId () {
    return ConnectionResource.calcCustomerIdFromConnectionId(this.id);
  }

  formattedCreatedAt () {
    const d = parseISO(this.createdAt);
    return isThisYear(d) ? format(d, 'MMMM do') : format(d, 'MMMM do yyyy');
  }

  businessFullName () {
    return this.businessProfile.contactName + ' @ ' + this.businessProfile.businessName;
  }

  businessContactName () {
    return this.businessProfile.contactName;
  }

  businessName () {
    return this.businessProfile.businessName;
  }

  static createForActivationShape<T extends typeof Resource> (this: T) {
    return {
      ...this.createShape(),
      fetch: (params: Readonly<Record<string, string | number>>, body: schemas.ConnectionCreateType
      ) => {
        return this.fetch('post', this.listUrl(params), body);
      }
    };
  }

  static url (urlParams: Readonly<Record<string, unknown>>) {
    if (Object.prototype.hasOwnProperty.call(urlParams, 'url') && urlParams.url && typeof urlParams.url === 'string') {
      return urlParams.url;
    }

    let tusSearchParam = '';
    if (urlParams.tus) {
      tusSearchParam = `?tus=${urlParams.tus}`;
    }

    if (this.pk(urlParams) !== undefined) {
      if (this.urlRoot.endsWith('/')) {
        return `${this.urlRoot}${this.pk(urlParams)}${tusSearchParam}`;
      }

      return `${this.urlRoot}/${this.pk(urlParams)}${tusSearchParam}`;
    }

    return this.urlRoot;
  }

  static urlRoot = '/connections';
}
