import { UserLayoutPage } from '../../containers/UserLayout';
import {
  IonButton,
  IonButtons,
  IonItem, IonLabel,
  IonMenuButton, IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { useParams } from 'react-router';
import { AvatarWithName } from '../../components/Avatars';
import React from 'react';
import { BusinessResource } from '../../models/business';
import { useResource } from 'rest-hooks';
import { Logo } from '../../components/Logos';
import { generatePublicActivationLink } from '../../components/Activations';

export default function SettingsOverview () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content />}
    />
  );
}

function Header () {
  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonMenuButton autoHide={true}/>
      </IonButtons>
      <IonTitle>Business</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

function Content () {
  const { businessId } = useParams<{ businessId: string }>();
  const business = useResource(BusinessResource.detailShape(), { id: businessId });

  return (<>
    <IonRow>
      <IonButton
        className='ml-auto'
        color="primary"
        fill="clear"
        routerLink={`/businesses/${businessId}/settings/edit`}
      >Edit</IonButton>
    </IonRow>
    <IonRow className='ion-justify-content-center my-2'>
      { business.logoImageUrl ? <Logo url={business.logoImageUrl} size={'lg'}/> : <span className={'text-muted'}>No Logo</span>}
    </IonRow>
    <IonRow className='ion-justify-content-center'>
      <AvatarWithName avatar={business.avatar} name={business.contactName + ' @ ' + business.businessName} size={'md'}/>
    </IonRow>
    <IonRow>
      <IonItem lines='none'>
        <IonLabel className={'text-wrap'}>
          <h2>New Customer Link</h2>
          <p>{generatePublicActivationLink(business)}</p>
        </IonLabel>
      </IonItem>
    </IonRow>
    <IonRow>
      <IonItem lines='none'>
        <IonLabel className={'text-wrap'}>
          <h2>Welcome Message</h2>
          <p>{business.welcomeMessage}</p>
        </IonLabel>
      </IonItem>
    </IonRow>
  </>);
}
