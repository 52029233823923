import React, { useContext, useState } from 'react';
import { BusinessContext, UserLayoutPage } from '../../containers/UserLayout';
import {
  IonBackButton,
  IonButtons,
  IonItem,
  IonTitle,
  IonToolbar, IonLabel, IonToast
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { useParams } from 'react-router';
import {
  activationSecretIdWithReferrer,
  CopyActivationLinkButtonForGroup,
  ShowActivationLinkQRCodeButtonForGroup
} from '../../components/Activations';
import { MissingSomethingWarning } from '../../components/Warnings';
import { Form, Formik, FormikHelpers } from 'formik';
import { IonFormikInput, UnexpectedFormErrors } from '../../components/Forms';
import { EditIcon, EmailFormLabelIcon, NameFormLabelIcon, PrivateGroupIcon, ReferrerCodeIcon } from '../../libs/icons';
import { useFetcher, useResource } from 'rest-hooks';
import { CustomerUserResource } from '../../models/user';
import { ConnectionResource } from '../../models/connection';
import { schemas } from 'sprancer-shared';
import { reportException } from '../../libs/errors';
import { SaveButton } from '../../components/FormButtons';
import { CustomerResource } from '../../models/customer';
import { ActivationResource } from '../../models/activation';
import { BusinessResource } from '../../models/business';
import * as yup from 'yup';
import { GroupSelect } from '../../components/Groups';
import { useHistory } from 'react-router-dom';
yup.setLocale({
  mixed: {
    required: 'required'
  }
});

export default function NewCustomer () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
    />
  );
}

function Header () {
  const { businessId } = useParams<{ businessId: string }>();

  return (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref={`/businesses/${businessId}/customers/`}/>
        </IonButtons>
        <IonTitle>Add Customers</IonTitle>
        <UserDropdown slot="end" />
      </IonToolbar>
    </>
  );
}

const NewCustomerFormSchema = yup.object({
  nickname: yup.string().required().default(''),
  avatar: yup.string().notRequired(),
  email: yup.string().email().required().default(''),
  referrer: yup.string().max(30).matches(/^[a-zA-Y0-9]+$/, 'Must be only numbers or letters (except Z)')
});

type NewCustomerFormType = schemas.TokenUserCreateType & {
  referrer?: string;
  groupId: string;
}

function Content () {
  const { businessId } = useParams<{ businessId: string }>();

  const history = useHistory<{privateGroupId: string}>();
  const privateGroupId = history.location.state?.privateGroupId;

  const business = useResource(BusinessResource.detailShape(), { id: businessId });
  const { privGroupsById } = useContext(BusinessContext);

  const [showToastMsg, setShowToastMsg] = useState('');

  const createUser = useFetcher(CustomerUserResource.createCustomerTokenUserShape());
  const createConnection = useFetcher(ConnectionResource.createForActivationShape());
  const refetchCustomers = useFetcher(CustomerResource.listShape());
  const sendActivation = useFetcher(ActivationResource.sendShape());

  if (!business.publicActivationSecretId) {
    return (
      <MissingSomethingWarning
        title={'No public activation link'}
        message={'Please create a public activation link before adding customers.'}
        buttonLink={`/businesses/${businessId}/settings/edit`}
        buttonTitle={'Edit Settings'} />
    );
  }
  const publicActivationSecretId = business.publicActivationSecretId;
  async function handleSubmit (values: NewCustomerFormType, actions: FormikHelpers<NewCustomerFormType>) {
    const group = privGroupsById.get(values.groupId);
    const activationSecretId = activationSecretIdWithReferrer(group?.activationSecretId || publicActivationSecretId, 'C', values.referrer);
    // Determine if we are a logged in cognito user.
    try {
      try {
        const user: schemas.UserType = await createUser({}, values);
        try {
          await createConnection({ tus: user.tokenUserSecret as string }, { activationSecretId });

          // fetch the new customer to populate the cache.  Do it asynchronously since we're ignoring errors anyway.
          refetchCustomers({ businessId }, {}).catch((e) => {
            reportException(e, 'refetchCustomers failed in NewCustomer Content handleSubmit');
          });

          actions.setSubmitting(false);
          actions.resetForm();
          setShowToastMsg('Customer added');
        } catch (e) {
          if (e.response?.status === 403) {
            // 403 means that the business isn't allowed any more connections
            reportException(e, 'handleSubmit createConnection create failed with 403 (too many customers) in NewCustomer Content handleSubmit');
            actions.setStatus('Too many customers.  Please contact support@sprancer.com');
            actions.setSubmitting(false);
          } else {
            reportException(e, 'handleSubmit createConnection create failed in NewCustomer Content handleSubmit');
            actions.setStatus(e.message || e);
            actions.setSubmitting(false);
          }
        }
      } catch (e) {
        if (e.response?.status === 401 || e.response?.status === 409) {
          // 409 means the email address is already in use by a token customer.
          // 401 means the email address is already in use.
          reportException(e, 'createUser failed with 401 or 409 in NewCustomer Content handleSubmit');// report this for now
          // send an activation link by email instead.
          await sendActivation({ id: activationSecretId }, { email: values.email });
          actions.setSubmitting(false);
          actions.resetForm();
          setShowToastMsg('Activation link sent');
        } else {
          throw e;
        }
      }
    } catch (e) {
      reportException(e, 'createUser failed in NewCustomer Content handleSubmit');
      actions.setStatus(e.message || e);
      actions.setSubmitting(false);
    }
  }

  const initialValues: NewCustomerFormType = { referrer: '', groupId: privateGroupId || '', ...schemas.TokenUserCreateSchema.required().default() };

  return (<>
    <Formik
      initialValues={initialValues}
      initialTouched={{ referrer: true }}
      onSubmit={handleSubmit}
      validationSchema={NewCustomerFormSchema} >
      {({ isSubmitting, dirty, values, errors, isValid }) => {
        const group = privGroupsById.get(values.groupId);
        return (
          <Form>
            <IonItem>
              <div className={'mr-2'}><PrivateGroupIcon /></div>
              <GroupSelect name='groupId' placeholder={'Private Group (optional)'} groupsById={privGroupsById} />
            </IonItem>
            <IonFormikInput name={'referrer'} type={'text'} placeholder={'Referrer Code (optional)'} label={<ReferrerCodeIcon />}/>
            <IonItem lines={'none'}>
              <IonLabel>Share a reusable link</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel>
                <ShowActivationLinkQRCodeButtonForGroup business={business} group={group} referrer={values.referrer} disabled={!!errors.referrer} />
              </IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel>
                <CopyActivationLinkButtonForGroup business={business} group={group} referrer={values.referrer} disabled={!!errors.referrer} />
              </IonLabel>
            </IonItem>
            <IonItem lines={'none'}>
              <IonLabel>Or add a customer directly</IonLabel>
            </IonItem>
            <UnexpectedFormErrors expectedErrors={['referrer', 'nickname', 'email']}/>
            <IonFormikInput name='nickname' type='text' autocapitalize={'words'} label={<NameFormLabelIcon />} placeholder='Name' />
            <IonFormikInput name='email' placeholder='Email' label={<EmailFormLabelIcon />} type='email' />
            <IonItem lines="none" className='text-center'>
              <IonLabel>
                <SaveButton
                  size='default'
                  expand={'block'}
                  text={<><div slot={'start'}><EditIcon size={'2em'} /></div> <div className={'ml-2'}>Add Customer</div></>}
                  loadingText={'Adding…'}
                  disabled={isSubmitting || !dirty || !isValid}
                  isLoading={isSubmitting}
                />
              </IonLabel>
            </IonItem>
          </Form>
        );
      }}
    </Formik>
    <IonToast
      isOpen={!!showToastMsg}
      position="top"
      onDidDismiss={() => setShowToastMsg('')}
      message={showToastMsg}
      duration={1000}
    />
  </>);
}
