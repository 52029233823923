import React from 'react';
import ActivationErrorBoundary from './ActivationErrorBoundary';
import { MissingSomethingWarning } from '../../components/Warnings';
import { Link } from 'react-router-dom';
import { ActivationBanner } from '../../components/Activations';
import { NoAuthLayoutPage } from '../../containers/NoAuthLayout';
import { IonCol, IonGrid, IonItem, IonRow } from '@ionic/react';
import { useBackground } from '../../libs/background';

export default function ActivationLinkExpired () {
  const { contentStyle } = useBackground();

  return (
    <ActivationErrorBoundary>
      <NoAuthLayoutPage
        header={<Header/>}
        content={<Content/>}
        contentStyle={contentStyle}
      />
    </ActivationErrorBoundary>
  );
}

function Header () {
  return (<></>);
}

function Content () {
  return (<>
    <ActivationBanner/>
    <IonGrid className='py-3 px-0'>
      <IonRow className='p-0'>
        <IonCol className='p-0' size={'12'} size-md={'8'} offset-md={'2'} size-xl={'6'} offset-xl={'3'}>
          <IonItem color='transparent' lines='none'>
            <h4 className={'text-muted text-break'}>Link expired</h4>
          </IonItem>
          <IonItem lines={'none'} className={'mb-3'}>
            <IonGrid className='p-0'>
              <IonRow className='p-0'>
                <IonCol className='p-0'>
                  <MissingSomethingWarning
                    warningIcon={true}
                    title={''}
                    message={'Please reach out to the business for a new link.'}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines={'none'}>
            <p>If you already connected you may <Link to={'/login/welcome'}>login to access your connection.</Link></p>
          </IonItem>
        </IonCol>
      </IonRow>
    </IonGrid>
  </>);
}
