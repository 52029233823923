import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useFetcher, useResource, useSubscription } from 'rest-hooks';
import * as lodash from 'lodash';
import { useIdleTimer } from 'react-idle-timer';
import { MessageResource } from '../models/message';
import crypto from 'crypto';
import { AlertResource } from '../models/alert';
import { ConnectionMessageResource } from '../models/connectionMessage';
import { useTokenUserSecret } from '../libs/tokenUserAuth';
import { reportException } from '../libs/errors';
import { AppContext } from '../libs/context';
import { CustomerResource } from '../models/customer';

type AlertsContextType = {
  pathToAlertIds: Map<string, string[]>;
};

type AlertSummary = {
  pathToAlertIds: Map<string, string[]>
};

export const AlertsContext = React.createContext<AlertsContextType>({
  pathToAlertIds: new Map<string, string[]>()
});

// To prevent too many simultaneous network requests limit the number of deletes at one time.  All alerts will still be
// deleted because once this fires, hrefToAlertIds will be updated causing the useEffect to fire again.
const MAX_PATCH_COLLECTION_IDS = 100;

export function useDeleteAlerts (path: string) {
  const { tus } = useTokenUserSecret();
  const { pathToAlertIds } = useContext(AlertsContext);
  const patchCollection = useFetcher(AlertResource.patchCollectionShape());

  useEffect(() => {
    const alertIds = pathToAlertIds.get(path) || [];
    if (alertIds.length > 0) {
      const alertIdsSlice = alertIds.length <= MAX_PATCH_COLLECTION_IDS ? alertIds : alertIds.slice(0, MAX_PATCH_COLLECTION_IDS);
      patchCollection({ ...tus && { tus } }, {
        action: 'delete',
        ids: alertIdsSlice
      },
      [
        [AlertResource.listShape(), { ...tus && { tus } }, (id, ids) => lodash.difference(ids, alertIdsSlice)]
      ]).catch((e) => {
        reportException(e, 'patchCollection failed in Alerts useDeleteAlerts useEffect');
      });
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []); // Empty deps because we only want this to fire once
}

export function useAlertSummary (setShowToastMsg: (msg: string) => void): AlertSummary {
  const { tus } = useTokenUserSecret();
  const { appIsActive } = useContext(AppContext);

  const [idle, setIdle] = useState(false);
  useIdleTimer({
    timeout: 1000 * 60 * 10, // 10 minutes
    onIdle: () => setIdle(true),
    onActive: () => setIdle(false),
    debounce: 500
  });

  const patchCollection = useFetcher(AlertResource.patchCollectionShape());

  const alerts: AlertResource[] = useResource(AlertResource.listShape(), { ...tus && { tus } });
  useSubscription(AlertResource.listShape(), idle || !appIsActive ? null : { ...tus && { tus } });

  const fetchConnectionMessage = useFetcher(ConnectionMessageResource.detailShape());
  const fetchCustomer = useFetcher(CustomerResource.detailShape());
  const fetchMessage = useFetcher(MessageResource.detailShape());

  const [prevAlertIds, setPrevAlertIds] = useState<Set<string>>(new Set(alerts.map(a => a.id)));

  // We only want to recalculate the alert summary when the alerts have changed, but since we're polling with
  // useSubscription we get a new alerts array every poll (even if the values haven't changed).  Calculate a hash
  // and use that as the dependency for useMemo so we only recaculate when there is an actual change.
  const alertsHash = createHash(alerts.map(a => `${a.id}${a.notifyAt}`));

  const [pathToAlertIds] = useMemo(() => {
    const pathToAlertIds = new Map<string, string[]>();

    let newAlertIds = false;
    const alertIdsWithNotify: string[] = [];

    for (const a of alerts) {
      // Fetch the corresponding new message for any new alerts.
      if (!prevAlertIds.has(a.id)) {
        newAlertIds = true;
        const fetchParams = a.getReferenceFetchParams();
        if (fetchParams) {
          switch (a.alertType) {
            case 'bizCustomerAlert':
              fetchCustomer(fetchParams, {}, [
                [
                  CustomerResource.listShape(),
                  { businessId: fetchParams.businessId },
                  (id, ids) => (ids || []).includes(id) ? ids : [...(ids || []), id]
                ]
              ]).then(() => {
                setShowToastMsg('New customer connected');
              }).catch((e) => {
                reportException(e, 'fetchCustomer failed in Alerts useAlertSummary');
              });
              break;
            case 'bizMessageAlert':
              fetchMessage(fetchParams, {}, [
                [
                  MessageResource.listShape(),
                  { businessId: fetchParams.businessId },
                  (id, ids) => (ids || []).includes(id) ? ids : [...(ids || []), id]
                ]
              ]).then(() => {
                setShowToastMsg('New message received');
              }).catch((e) => {
                reportException(e, 'fetchMessage failed in Alerts useAlertSummary');
              });
              break;
            case 'connMessageAlert':
              fetchConnectionMessage({ ...fetchParams, ...tus && { tus } }, {}, [
                [
                  ConnectionMessageResource.listShape(),
                  { connectionId: fetchParams.connectionId, ...tus && { tus } },
                  (id, ids) => (ids || []).includes(id) ? ids : [...(ids || []), id]
                ]
              ]).then(() => {
                setShowToastMsg('New message received');
              }).catch((e) => {
                reportException(e, 'fetchConnectionMessage failed in Alerts useAlertSummary');
              });
              break;
          }
        }
      }

      if (a.notifyAt) {
        alertIdsWithNotify.push(a.id);
      }

      // Remap reference paths from posts to messages
      // TODO: Remove this once the backend is updated.
      const refpath = a.referencePath.replace('/posts/', '/messages/');

      // calc the the map of href to all alert ids.  This is used for showing the count and deleting alerts.
      const splitPath = refpath.split('/');
      let prefix = '';
      for (let i = 1; i < splitPath.length; i++) {
        prefix += '/' + splitPath[i];

        const alertIds = pathToAlertIds.get(prefix);
        if (alertIds) {
          alertIds.push(a.id);
        } else {
          pathToAlertIds.set(prefix, [a.id]);
        }
      }
    }

    // we're logged in and so prevent any future notifications for the alerts we are seeing.  For the sake of throttling,
    // only send at most 100 ids at one time.
    if (alertIdsWithNotify.length > 0) {
      patchCollection({ ...tus && { tus } }, {
        action: 'clearNotify',
        ids: alertIdsWithNotify.length <= MAX_PATCH_COLLECTION_IDS ? alertIdsWithNotify : alertIdsWithNotify.slice(0, MAX_PATCH_COLLECTION_IDS)
      },
      []).catch((e) => {
        reportException(e, 'patchCollection failed in Alerts useAlertSummary');
      });
    }

    // update the list of 'known' alertIds so we don't refetch messages that we already know about.
    if (newAlertIds) {
      setPrevAlertIds(new Set(alerts.map(a => a.id)));
    }

    // return values.  It's still sorted because map preserves the insertion order.
    return [pathToAlertIds];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertsHash]);

  return { pathToAlertIds: pathToAlertIds };
}

function createHash (data: string[]) {
  const c = crypto.createHash('sha1');
  for (const d of data) {
    c.update(d);
  }
  return c.digest('base64');
}
