import React, { useMemo } from 'react';
import { useResource } from 'rest-hooks';
import { ConnectionMessageResource } from '../../models/connectionMessage';
import { Route, useParams } from 'react-router';
import { ConnectionResource } from '../../models/connection';
import ConnectionMessageList from './ConnectionMessageList';
import ConnectionMessageDetails from './ConnectionMessageDetails';
import { useTokenUserSecret } from '../../libs/tokenUserAuth';
import { Redirect, Switch } from 'react-router-dom';
import { IonRouterOutletWrapper } from '../../components/RouterOutlet';
import { ConnectionMessageRefresher } from '../../libs/resourceRefresher';
import ShareConnection from './ShareConnection';
import ConnectionNotifications from './ConnectionNotifications';

export default function ConnectionMessages () {
  const { connectionId } = useParams<{ connectionId: string }>();
  const { tus, tusifyUrl } = useTokenUserSecret();

  const connection = useResource(ConnectionResource.detailShape(), { id: connectionId, ...tus && { tus } });
  const connectionMessages = useResource(ConnectionMessageResource.listShape(), { connectionId: connectionId, ...tus && { tus } });

  const [threads, threadsById] = useMemo(() => {
    const { threadsById } = ConnectionMessageResource.partitionMessagesByThread(connectionMessages);
    return [Array.from(threadsById.values()), threadsById];
  }, [connectionMessages]);

  // IonRouterOutletWrapper for an explanation of this nonsense.
  const routes = [
    {
      path: '/connections/:connectionId/messages/:threadId',
      name: 'Message Detail',
      component: <ConnectionMessageRefresher connectionId={connectionId} tus={tus}><ConnectionMessageDetails connection={connection} threadsById={threadsById} /></ConnectionMessageRefresher>
    },
    {
      path: '/connections/:connectionId/messages/share/share',
      name: 'Share Connection',
      component: <ShareConnection connection={connection} />
    },
    {
      path: '/connections/:connectionId/messages',
      name: 'Messages',
      component: <ConnectionMessageRefresher connectionId={connectionId} tus={tus}><ConnectionMessageList connection={connection} threads={threads} /></ConnectionMessageRefresher>
    },
    {
      path: '/connections/:connectionId/messages/notifications',
      name: 'Notifications',
      component: <ConnectionNotifications />
    }
  ];

  return (
    <Switch>
      <Route exact path={routes[0].path} name={routes[0].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[1].path} name={routes[1].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[2].path} name={routes[2].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[3].path} name={routes[3].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Redirect to={tusifyUrl('/connections/:connectionId/messages')} />
    </Switch>
  );
}
