import React from 'react';
import { CancelIcon, CreateIcon, DeleteIcon, SaveIcon } from '../libs/icons';
import { IonSpinner, IonButton } from '@ionic/react';
import { IconType } from 'react-icons';

type LoaderButtonProps = {
  isLoading: boolean,
  text: string | JSX.Element,
  loadingText: string | JSX.Element,
  className?: string,
  disabled?: boolean,
} & Parameters<typeof IonButton>[0];

export function LoaderButton ({ isLoading, text, loadingText, disabled = false, ...props }: LoaderButtonProps) {
  return (
    <IonButton disabled={disabled || isLoading} {...props}>
      {isLoading
        ? <><IonSpinner role='status' aria-hidden='true'/> {loadingText}</>
        : text
      }
    </IonButton>
  );
}

export function SaveButton ({ text = <><SaveIcon/> Save</>, loadingText = 'Saving…', ...props }:
  {
    text?: string | JSX.Element,
    loadingText?: string | JSX.Element
  } & Omit<LoaderButtonProps, 'text' | 'loadingText'>) {
  return (
    <LoaderButton
      color='primary'
      text={text}
      loadingText={loadingText}
      type='submit'
      {...props}
    />);
}

export function CancelClickButton ({ text = <><CancelIcon/> Cancel</>, onClick, ...props }:
  {
    text?: string | JSX.Element
  } & Parameters<typeof IonButton>[0]) {
  return (
    <IonButton
      color='danger'
      onClick={onClick}
      {...props}
    >{text}
    </IonButton>);
}

type IconButtonProps = Omit<LoaderButtonProps, 'text' | 'loadingText'>;
export function IconSubmitButton ({ icon: ButtonIcon, ...props } : { icon: IconType } & IconButtonProps) {
  return (
    <LoaderButton
      color='primary'
      size='default'
      text={<ButtonIcon size='2em'/>}
      loadingText=''
      type='submit'
      {...props}
       />
  );
}

export function IconCreateButton (props : IconButtonProps) {
  return (<IconSubmitButton icon={CreateIcon} {...props} />);
}

export function IconDeleteButton (props : IconButtonProps) {
  return (<IconSubmitButton icon={DeleteIcon} color='danger' {...props} />);
}

export function IconSaveButton (props : IconButtonProps) {
  return (<IconSubmitButton icon={SaveIcon} {...props} />);
}

export function IconSendButton (props : IconButtonProps) {
  return (<IconSubmitButton icon={SaveIcon} {...props} />);
}

export function IconCancelButton (props: Parameters<typeof IonButton>[0]) {
  return (
    <IonButton size='default' color='danger' fill='outline' {...props} >
      <CancelIcon size='2em'/>
    </IonButton>
  );
}

export function TextSaveButton ({ text = 'Save', loadingText = 'Saving…', submitOnEnter = false, ...props }:
  { text?: string | JSX.Element, loadingText?: string | JSX.Element, submitOnEnter?: boolean} & Omit<LoaderButtonProps, 'text' | 'loadingText'>) {
  return (<>
      <LoaderButton
        {...props}
        expand='block'
        size='default'
        type='submit'
        text={text}
        loadingText={loadingText}
      />
      {/* Add a hidden submit button to fix a problem where IonButtons won't submit on enter because they are in the shadow dom */}
      { submitOnEnter && <button type='submit' style={{ visibility: 'hidden', position: 'absolute' }}/> }
    </>
  );
}
