import { useTokenUserSecret } from '../../libs/tokenUserAuth';
import { useHistory, useParams } from 'react-router';
import { useFetcher, useResource } from 'rest-hooks';
import { ActivationResource } from '../../models/activation';
import {
  IonButton,
  IonButtons,
  IonItem,
  IonLabel,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { useGetCurrentUser, useRefetchCurrentUser, UserResource } from '../../models/user';
import { ConnectionResource } from '../../models/connection';
import { Form, Formik, FormikHelpers } from 'formik';
import { reportException } from '../../libs/errors';
import * as yup from 'yup';
import { IonFormikCheckbox, IonFormikInput, UnexpectedFormErrors } from '../../components/Forms';
import { TextSaveButton } from '../../components/FormButtons';
import React, { Component, ErrorInfo } from 'react';
import { UserLayoutPage } from '../../containers/UserLayout';
import { UserDropdown } from '../../components/Dropdowns';
import { ActivationWelcome } from '../../components/Activations';
import { NameFormLabelIcon } from '../../libs/icons';
import { schemas } from 'sprancer-shared';
import { MissingSomethingWarning } from '../../components/Warnings';

export default function UserActivationOverview () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<UserActivationErrorBoundary><Content /></UserActivationErrorBoundary>}
    />
  );
}

function Header () {
  const { tusifyUrl } = useTokenUserSecret();

  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonButton routerLink={tusifyUrl('/user/home')}>Cancel</IonButton>
      </IonButtons>
      <IonTitle>New Business</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

function Content () {
  const { activationId } = useParams<{ activationId: string }>();
  const activation = useResource(ActivationResource.detailShape(), { id: activationId });

  return (
    <div>
      <ActivationWelcome activationId={activationId} />
      <ConnectForm activation={activation} />
    </div>
  );
}

const ConnectFormSchema = yup.object({
  nickname: yup.string().defined().default(''),
  selectedGroupIds: yup.array().of(yup.string().required()).defined().default([])
});

type ConnectFormType = {
  nickname: string;
  selectedGroupIds: string[]
};

function ConnectForm ({ activation }: {activation: ActivationResource}) {
  const history = useHistory();
  const { tus, tusifyUrl } = useTokenUserSecret();

  const user = useGetCurrentUser();
  const refetchUser = useRefetchCurrentUser();
  const patch = useFetcher(UserResource.partialUpdateShape());

  const create = useFetcher(ConnectionResource.createForActivationShape());
  const update = useFetcher(ConnectionResource.updateShape());

  async function handleSubmit (values: ConnectFormType, actions: FormikHelpers<ConnectFormType>) {
    try {
      if (values.nickname?.length > 0) {
        // the user hasn't set a name yet.  Use the contact name the provided to update their user profile.
        await patch({ id: user.userId, ...tus && { tus } }, { nickname: values.nickname });
      }

      const connection = await create({ ...tus && { tus } }, { activationSecretId: activation.id }) as schemas.ConnectionType;
      try {
        // set any deselected groups as hidden while preserving all the other group settings
        const groups = [...(connection.notifications?.groups || [])];
        activation.businessProfile.groups.forEach(g => {
          if (!values.selectedGroupIds.includes(g.id)) {
            // hide the group
            const groupInfo = groups.find(g2 => g2.id === g.id);
            if (groupInfo) {
              groupInfo.freq = 'hide';
            } else {
              groups.push({ id: g.id, freq: 'hide' });
            }
          } else {
            // make sure the group isn't hidden
            const groupInfo = groups.find(g2 => g2.id === g.id);
            if (groupInfo && groupInfo.freq === 'hide') {
              groupInfo.freq = schemas.DEFAULT_FREQUENCY;
            }
          }
        });

        await update(
          { id: connection.id, ...tus && { tus } },
          {
            notifications: {
              direct: connection.notifications?.direct || 'instant',
              other: connection.notifications?.other || schemas.DEFAULT_FREQUENCY,
              groups: groups
            }
          }
        );
      } catch (e) {
        // hmm couldn't update the connection.  Report and ignore.
        reportException(e, 'handleSubmit update failed in Activation UnauthConnectForm');
      }

      // we succeeded in adding the connection, now refetch the user.  we can do this in the background
      refetchUser().catch(e => reportException(e, 'refetchUser failed in UserActivationOverview ConnectForm'));

      actions.setSubmitting(false);

      history.replace({
        pathname: `/connections/${connection.id}/messages`,
        search: tusifyUrl(''),
        state: { newActivation: true }
      });
    } catch (e) {
      if (e.response?.status === 403) {
        // 403 means that the business isn't allowed any more connections, send them to the Link Expired page.
        reportException(e, 'handleSubmit failed with 403 in UserActivationOverview ConnectForm');
        history.push(tusifyUrl(`/activations/${activation.id}/linkexpired`));
      } else {
        reportException(e, 'handleSubmit failed in UserActivationOverview ConnectForm');
        actions.setStatus(e.message || e);
        actions.setSubmitting(false);
      }
    }
  }

  const initialValues: ConnectFormType = ConnectFormSchema.required().default();
  initialValues.selectedGroupIds = activation.businessProfile.groups.map(group => group.id);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={!user.nickname && yup.object({ nickname: yup.string().required() })}>
      {({ isSubmitting }) => (
        <Form>
          <UnexpectedFormErrors expectedErrors={['nickname']}/>
          { !user.nickname && <IonFormikInput name='nickname' type='text' placeholder='Name' autocomplete={'name'} autocapitalize={'words'} label={<NameFormLabelIcon />} helpText={`Tell ${activation.businessProfile.contactName} who I am.`}/> }
          { activation.businessProfile.groups.length > 1 &&
            <div className={'mb-3'}>
              <IonItem lines='none'>
                <IonLabel>
                  <p className={'text-wrap text-muted'}>
                    Choose everything that interests you
                  </p>
                </IonLabel>
              </IonItem>
              {
                activation.businessProfile.groups.map(group => {
                  return <IonItem key={group.id} lines={'full'}>
                    <IonFormikCheckbox name='selectedGroupIds' value={group.id} slot={'start'}/> {group.name}
                  </IonItem>;
                })
              }
            </div>
          }
          <IonItem lines="none" className='text-center'>
            <IonLabel>
              <TextSaveButton text='Connect' loadingText={'Connecting…'} disabled={isSubmitting} isLoading={isSubmitting} />
            </IonLabel>
          </IonItem>
        </Form>
      )}
    </Formik>
  );
}

class UserActivationErrorBoundary extends Component {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public state: { error: any } = {
    error: null
  };

  public static getDerivedStateFromError (error: Error) {
    return { error };
  }

  public componentDidCatch (error: Error, info: ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, info);
  }

  public render () {
    if (this.state.error?.status === 400 || this.state.error?.status === 404 || this.state.error?.status === 410) {
      return (
        <MissingSomethingWarning
          warningIcon={true}
          title={'This link has expired'}
          message={'Please reach out to the business for a new link.'}
        />
      );
    }

    return this.props.children;
  }
}
