import { useLocation, useParams } from 'react-router';
import { ConnectionResource } from '../../models/connection';
import { ConnectionMessageResource, ConnectionThread } from '../../models/connectionMessage';
import React, { useContext, useEffect, useState } from 'react';
import { AvatarWithName } from '../../components/Avatars';
import { useDeleteAlerts } from '../../components/Alerts';
import {
  IonBackButton,
  IonButtons,
  IonItem,
  IonTitle,
  IonToolbar, IonButton, IonPopover, IonRow, IonModal, IonGrid, IonCol
} from '@ionic/react';
import { UserLayoutPage, UserLayoutContext } from '../../containers/UserLayout';
import { ChatMessageList, ChatMessageCreateToolbar, ChatMessagePageContext } from '../../components/ChatMessageList';
import { useFetcher } from 'rest-hooks';
import { MessageContent } from '../../components/Messages';
import { AddReactionIcon, ReactionsIcons } from '../../libs/icons';
import { reportException } from '../../libs/errors';
import { channels, schemas } from 'sprancer-shared';
import { useTokenUserSecret } from '../../libs/tokenUserAuth';
import { UserDropdown } from '../../components/Dropdowns';
import { Logo } from '../../components/Logos';
import { useHistory } from 'react-router-dom';

export default function ConnectionMessageDetails ({ connection, threadsById }:
  {
    connection: ConnectionResource,
    threadsById: Map<string, ConnectionThread>
  }) {
  const { threadId } = useParams<{ threadId: string }>();
  const newMessageId = `newMessage${threadId}`;
  const [editMessageId, setEditMessageId] = useState('');

  return (
    <ChatMessagePageContext.Provider value={{ newMessageId, newMessagePlaceholder: 'Enter a new message', editMessageId, setEditMessageId }} >
      <UserLayoutPage
        header={<Header/>}
        content={
          <Content
            connection={connection}
            threadsById={threadsById}
          />}
        footer={<Footer />}
      />
    </ChatMessagePageContext.Provider>
  );
}

function Header () {
  const { tusifyUrl } = useTokenUserSecret();
  const { connectionId } = useParams<{ connectionId: string }>();

  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton defaultHref={tusifyUrl(`/connections/${connectionId}/messages`)}/>
      </IonButtons>
      <IonTitle>Message Details</IonTitle>
      <UserDropdown slot="end" />
    </IonToolbar>
  );
}

function Footer () {
  return (
    <>
      <ChatMessageCreateToolbar />
    </>
  );
}

function Content ({ connection, threadsById }:
  {
    connection: ConnectionResource,
    threadsById: Map<string, ConnectionThread>
  }) {
  const history = useHistory<{fromNewConnectionHowTo?: boolean}>();
  const fromNewConnectionHowTo = !!history.location.state?.fromNewConnectionHowTo;

  const [showModal, setShowModal] = useState(fromNewConnectionHowTo);

  useEffect(() => {
    if (fromNewConnectionHowTo) {
      history.replace({ ...history.location, state: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromNewConnectionHowTo]);

  const { threadId } = useParams<{ threadId: string }>();
  const thread = threadsById.get(threadId);

  // scroll to the bottom if there is at least one reply.  This means we won't scroll to the bottom if it is just a
  // really long first message.
  const { contentRef } = useContext(UserLayoutContext);
  useEffect(() => {
    thread && thread.firstMsg !== thread.lastMsg && contentRef && contentRef.current && contentRef.current.scrollToBottom(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef]);

  const location = useLocation();
  useDeleteAlerts(location.pathname); // We're reading the message no so delete the "unread" alerts

  if (!thread && !channels.isDirectThreadId(threadId)) {
    return (<p className='text-muted'>This message is no longer available.  Messages expire after 30 days.</p>);
  }

  const sortedMessages = thread ? thread.sortedMessages() : [];
  const direct = !thread || thread.isDirect();

  const customerId = ConnectionResource.calcCustomerIdFromConnectionId(connection.id);
  const hasThreadMessage = !direct && !sortedMessages[0].fromMe(customerId);

  return (
    <div>
      <FromNewConnectionHowToModal connection={connection} show={showModal} close={() => setShowModal(false)}/>
      <div className='mb-2'>
        <IonRow className='ion-justify-content-center'>
          <IonItem lines='none'><AvatarWithName avatar={connection.businessProfile.avatar} name={connection.businessFullName()}/></IonItem>
        </IonRow>
        { hasThreadMessage && <ConnectionMessageCard connectionId={connection.id} message={sortedMessages[0]}/> }
        <ConnectionMessageChat
          chatMessages={ hasThreadMessage ? sortedMessages.slice(1) : sortedMessages }
          connectionId={connection.id}
        />
      </div>
      <div className='text-muted small ion-text-center mb-2'>Messages expire after 30 days.</div>
    </div>
  );
}

function ConnectionMessageCard ({ connectionId, message }: { connectionId: string, message: ConnectionMessageResource }) {
  const reactions = new Set(message.reactions);

  return (
    <div className='bubblerow yours last mb-3'>
      <div className={'bubble pt-0 last'}>
        <div className={'d-flex'}>
          <div className={'my-auto small text-muted'}>{message.formattedDate()}</div>
          <div className={'ml-auto'}><ReactionsDropdown connectionId={connectionId} messageId={message.id} reactions={reactions} /></div>
        </div>
        <MessageContent message={message} />
        {reactions.size > 0 &&
          <div className='text-primary ion-text-right'>
            {ReactionsIcons.map(([r, icon]) => (reactions.has(r) ? <span key={r} className='pl-1'>{icon}</span> : ''))}
          </div>
        }
      </div>
    </div>
  );
}

function ReactionsDropdown ({ connectionId, messageId, reactions }: {connectionId: string, messageId: string, reactions: Set<schemas.ReactionType>}) {
  const { tus } = useTokenUserSecret();
  const createReaction = useFetcher(ConnectionMessageResource.createReactionShape());
  const deleteReaction = useFetcher(ConnectionMessageResource.deleteReactionShape());

  async function handleUpdateReactions (create: boolean, messageId: string, reaction: string) {
    try {
      if (create) {
        await createReaction({ connectionId: connectionId, id: messageId, reactionId: reaction, ...tus && { tus } }, {}, []);
      } else {
        await deleteReaction({ connectionId: connectionId, id: messageId, reactionId: reaction, ...tus && { tus } }, {}, []);
      }
    } catch (e) {
      reportException(e, 'createReaction or deleteReaction failed in ConnectionMessageDetails ReactionsDropdown');
    }
  }

  const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({
    open: false,
    event: undefined
  });

  function close () {
    setShowPopover({ open: false, event: undefined });
  }

  return (
    <>
      <IonPopover
        cssClass='dropdown-popover'
        isOpen={showPopover.open}
        event={showPopover.event}
        onDidDismiss={close} >
        {ReactionsIcons.map(([reaction, icon]) =>
          <div key={reaction}>
            <IonButton
              onClick={() => {
                close();
                handleUpdateReactions(!reactions.has(reaction), messageId, reaction).catch(e =>
                  reportException(e, 'handleUpdateReactions failed in ConnectionMessageDetails ReactionsDropdown')
                );
              }}
              className={'px-0 m-0 ion-rounded-0'}
              color='primary'
              fill={reactions.has(reaction) ? 'solid' : 'clear'}>
              {icon}
            </IonButton>
          </div>
        )}
      </IonPopover>
      <IonButton slot="end" fill="clear" onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent })}>
        <AddReactionIcon size={'1.5em'}/>
      </IonButton>
    </>
  );
}

function ConnectionMessageChat ({ chatMessages, connectionId }:
  {
    chatMessages: ConnectionMessageResource[];
    connectionId: string;
  }) {
  const { threadId } = useParams<{ threadId: string }>();
  const { tus } = useTokenUserSecret();
  const update = useFetcher(ConnectionMessageResource.updateShape());
  const del = useFetcher(ConnectionMessageResource.deleteShape());
  const create = useFetcher(ConnectionMessageResource.createShape());

  const customerId = ConnectionResource.calcCustomerIdFromConnectionId(connectionId);
  return (
    <ChatMessageList
      currentConnectionOrBusinessId={customerId}
      sortedMessages={chatMessages}
      allowImage={false}
      update={(id: string, text: string) => update({ id, connectionId, ...tus && { tus } }, { text })}
      del={(id: string) => del({ id, connectionId, ...tus && { tus } }, undefined)}
      create={(text: string) => create({ connectionId: connectionId, ...tus && { tus } }, { text, threadId }, [
        [ConnectionMessageResource.listShape(), { connectionId: connectionId, ...tus && { tus } }, (id, ids) => [...(ids || []), id]]
      ])}
    />
  );
}

function FromNewConnectionHowToModal ({ connection, show, close }: { connection: ConnectionResource, show: boolean; close: () => void }) {
  return (
    <IonModal isOpen={show} onDidDismiss={close} >
      <IonGrid>
        <IonRow><IonCol><p className={'text-muted small'}>You&apos;re connected with</p></IonCol></IonRow>
        { connection.businessProfile.logoImageUrl && <IonRow className='ion-justify-content-center'>
          <IonItem lines='none'>
            <Logo url={connection.businessProfile.logoImageUrl} size={'lg'}/>
          </IonItem>
        </IonRow> }
        <IonRow>
          <blockquote className="blockquote">
            <p className='text-preline'>
              Thanks for connecting to Sprancer!  Tell us a little bit about the business you would like to connect to so we can get started.</p>
            <footer className="d-flex ion-justify-content-center"><IonItem lines='none'><span className={'text-muted mr-1'}>&mdash;</span><AvatarWithName avatar={connection.businessProfile.avatar} name={connection.businessFullName()}/></IonItem></footer>
          </blockquote>
        </IonRow>
        <IonRow className='ion-justify-content-center'>
          <IonCol sizeXs={'10'}><IonButton expand={'block'} size='default' color="primary" onClick={close}>OK</IonButton></IonCol>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
}
