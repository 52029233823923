import React, { useEffect } from 'react';
import { useResetter } from 'rest-hooks';
import { Redirect } from 'react-router-dom';

export default function Logout () {
  const resetCache = useResetter();
  useEffect(() => {
    console.log('RESTTING');
    resetCache();
    console.log('RESTTED');
  });
  console.log('LOGGEDOUT');
  return (<Redirect to={'/login/welcome'}/>);
}
