import React, { Component, ErrorInfo, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonList,
  IonMenu,
  IonPage,
  IonRow,
  IonSplitPane,
  IonToolbar,
  IonTitle,
  IonFooter,
  IonItemDivider, IonButtons, IonMenuButton, IonToast
} from '@ionic/react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../views/Errors/ErrorBoundary';
import UserHome from '../views/User/UserHome';
import UserProfile from '../views/User/UserProfile';
import UserErrorBoundary from './UserErrorBoundary';
import UserNewBusiness from '../views/User/UserNewBusiness';
import { useTokenUserSecret } from '../libs/tokenUserAuth';
import { ErrorIcon, HomeIcon, NotificationsIcon, UserIcon } from '../libs/icons';
import { BusinessMenuItem, ConnectionMenuItem, MenuItem } from '../components/Layout';
import { Loading } from '../components/Loading';
import { useGetCurrentUser, UserResource } from '../models/user';
import { AlertsContext, useAlertSummary } from '../components/Alerts';
import Customers from '../views/Customers/Customers';
import Groups from '../views/Groups/Groups';
import Messages from '../views/Messages/Messages';
import Settings from '../views/Settings/Settings';
import ConnectionMessages from '../views/ConnectionMessages/ConnectionMessages';
import LOGO from '../assets/img/brand/logo.svg';
import BusinessDashboard from '../views/Businesses/BusinessDashboard';
import { MessageResource, PostThread, DirectMessageThread } from '../models/message';
import { CustomerResource } from '../models/customer';
import { GroupResource } from '../models/group';
import { useParams } from 'react-router';
import { useResource } from 'rest-hooks';
import { BusinessResource } from '../models/business';
import * as lodash from 'lodash';
import UserNewBusinessHowTo from '../views/User/UserNewBusinessHowTo';
import UserNewConnectionHowTo from '../views/User/UserNewConnectionHowTo';
import UserNotifications from '../views/User/UserNotifications';
import UserRedeemCode from '../views/User/UserRedeemCode';
import { usePushNotifications } from '../libs/pushNotifications';
import { Plugins } from '@capacitor/core';
import UserActivations from '../views/UserActivations/UserActivations';
import { reportException } from '../libs/errors';
import { UserDropdown } from '../components/Dropdowns';
import { useUserDeviceInfo } from '../libs/capacitorApp';

const { SplashScreen } = Plugins;

const ROUTES = [
  { path: '/user/home', exact: true, component: UserHome },
  { path: '/user/notifications', component: UserNotifications },
  { path: '/user/profile', component: UserProfile },
  { path: '/user/newBusiness', exact: false, component: UserNewBusiness },
  { path: '/user/newBusinessHowTo', exact: false, component: UserNewBusinessHowTo },
  { path: '/user/newConnectionHowTo', exact: false, component: UserNewConnectionHowTo },
  { path: '/user/redeemCode', exact: false, component: UserRedeemCode },

  { path: '/activations/:activationId', exact: false, component: UserActivations },

  { path: '/businesses/:businessId', exact: false, component: BusinessContextLayout },

  { path: '/connections/:connectionId/messages', name: 'Messages', component: ConnectionMessages },
  { path: '/connections/:connectionId/notifications', name: 'Notifications', component: ConnectionMessages }
];

const BUSINESS_ROUTES = [
  { path: '/businesses/:businessId/customers', exact: false, component: Customers },
  { path: '/businesses/:businessId/dashboard', exact: false, component: BusinessDashboard },
  { path: '/businesses/:businessId/groups', exact: false, component: Groups },
  { path: '/businesses/:businessId/messages', exact: false, component: Messages },
  { path: '/businesses/:businessId/posts', exact: false, component: Messages },
  { path: '/businesses/:businessId/settings', exact: false, component: Settings }
];

export default function UserLayout () {
  return (
    <ErrorBoundary>
      <UserErrorBoundary>
        <Suspense fallback={<Loading />}>
          <UserLayoutMain/>
        </Suspense>
      </UserErrorBoundary>
    </ErrorBoundary>
  );
}

function calcPermissions (user: UserResource):
  { businessPerms: { name: string, path: string }[], connectionPerms: { name: string, path: string }[]} {
  if (user.permissions && user.permissions.length > 0) {
    const sortedPerms = lodash.sortBy(user?.permissions || [], ['name']);
    const [businessPerms, connectionPerms] = lodash.partition(sortedPerms, p => p.path.startsWith('/businesses/'));
    return { businessPerms, connectionPerms };
  } else {
    return { businessPerms: [], connectionPerms: [] };
  }
}

export function UserLayoutMain () {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    SplashScreen.hide();
  }, []);

  const { tusifyUrl } = useTokenUserSecret();

  const user = useGetCurrentUser();
  const [showToastMsg, setShowToastMsg] = useState('');
  const { pathToAlertIds } = useAlertSummary(setShowToastMsg);

  usePushNotifications(user);
  useUserDeviceInfo(user);

  const { businessPerms, connectionPerms } = calcPermissions(user);

  return (
    <AlertsContext.Provider value={{ pathToAlertIds }}>
      <IonSplitPane contentId="main">
        {/* --  the side menu  -- */}
        <IonMenu contentId="main">
          <IonHeader>
            <IonToolbar>
              <IonTitle><img style={{ height: '2rem' }} src={LOGO} /></IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color='dark' forceOverscroll={false}>
            <IonList className='py-0'>
              <MenuItem routerLink={'/user/home'} icon={HomeIcon} title={'Home'}/>
              { businessPerms.map(({ name, path }) => <BusinessMenuItem key={path} name={name} path={path} />) }
              <ConnectionMenuItem connectionPerms={connectionPerms} />
              <IonItemDivider color={'dark'}/>
              <MenuItem routerLink={'/user/notifications'} icon={NotificationsIcon} title={'Notifications'}/>
              <MenuItem routerLink={'/user/profile'} icon={UserIcon} title={'Profile'}/>
            </IonList>
          </IonContent>
        </IonMenu>

        {/* -- the main content -- */}
        <IonPage id="main">
          <IonToast
            isOpen={!!showToastMsg}
            position="top"
            onDidDismiss={() => setShowToastMsg('')}
            message={showToastMsg}
            duration={1000}
          />
          <Switch>
            {ROUTES.map((route, idx) => {
              return route.component
                ? (<Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    render={() => (
                      <UserLayoutErrorBoundary>
                        <Suspense fallback={<Loading />}>
                          <route.component/>
                        </Suspense>
                      </UserLayoutErrorBoundary>
                    )}
                  />
                  )
                : (null);
            })}

            <Redirect from={'/connections/:connectionId'} to={tusifyUrl('/connections/:connectionId/messages')} />
            <Redirect to={tusifyUrl(calcDefaultRoute(user))}/>
          </Switch>
        </IonPage>
      </IonSplitPane>
    </AlertsContext.Provider>
  );
}

function calcDefaultRoute (user: UserResource) {
  return user.permissions && user.permissions.length === 1 ? user.permissions[0].path : '/user/home';
}

export const BusinessContext = React.createContext<{
  customersById: Map<string, CustomerResource>;
  pubGroupsById: Map<string, GroupResource>;
  privGroupsById: Map<string, GroupResource>;

  postThreadsById: Map<string, PostThread>;
  dmThreadsByIdAndChannelId: Map<string, DirectMessageThread>;

  groupToCustomerIds: Map<string, Set<string>>;
}>({
  customersById: new Map<string, CustomerResource>(),
  pubGroupsById: new Map<string, GroupResource>(),
  privGroupsById: new Map<string, GroupResource>(),

  postThreadsById: new Map<string, PostThread>(),
  dmThreadsByIdAndChannelId: new Map<string, DirectMessageThread>(),

  groupToCustomerIds: new Map<string, Set<string>>()
});

function BusinessContextLayout () {
  const { businessId } = useParams<{ businessId: string }>();
  const [, customers, groups, messages] = useResource(
    [BusinessResource.detailShape(), { id: businessId }],
    [CustomerResource.listShape(), { businessId }],
    [GroupResource.listShape(), { businessId }],
    [MessageResource.listShape(), { businessId }]
  );

  const { postThreadsById, dmThreadsByIdAndChannelId } = useMemo(() => {
    return MessageResource.partitionMessagesByThread(messages);
  }, [messages]);

  const [groupToCustomerIds] = useMemo(() => {
    return [calculateGroupIdToCustomerIds(groups, customers)];
  }, [groups, customers]);

  const [privGroupsById, pubGroupsById] = useMemo(() => {
    const [sortedPrivGroups, sortedPubGroups] = lodash.partition(lodash.sortBy(groups, 'name'), g => g?.priv);
    return [new Map(sortedPrivGroups.map(g => [g.id, g])), new Map(sortedPubGroups.map(g => [g.id, g]))];
  }, [groups]);

  const customersById = useMemo(() => {
    const sortedCustomers = lodash.sortBy(customers, c => (c.nickname || '').toLowerCase());
    return new Map(sortedCustomers.map(c => [c.id, c]));
  }, [customers]);

  return (
    <BusinessContext.Provider value={{
      customersById,
      pubGroupsById,
      privGroupsById,

      postThreadsById,
      dmThreadsByIdAndChannelId,

      groupToCustomerIds
    }}>
      <Switch>
        {BUSINESS_ROUTES.map((route, idx) => {
          return (<Route
                key={idx}
                path={route.path}
                exact={route.exact}
                render={() => (<route.component/>)}
              />);
        })}
        <Redirect from={'/businesses/:businessId'} to={'/businesses/:businessId/dashboard'} />
      </Switch>
    </BusinessContext.Provider>
  );
}

function calculateGroupIdToCustomerIds (groups: GroupResource[], customers: CustomerResource[]): Map<string, Set<string>> {
  const allCustomerIds = customers.map(c => c.id);
  const gToC = new Map(groups.map(g => [g.id, new Set<string>(g.priv ? [] : allCustomerIds)]));

  for (const customer of customers) {
    for (const groupId of customer.groupIds) {
      const set = gToC.get(groupId);
      set && set.add(customer.id);
    }
  }

  return gToC;
}

export const UserLayoutContext = React.createContext<{ contentRef: React.RefObject<HTMLIonContentElement> | null }>({ contentRef: null });

export function UserLayoutPage ({ header, content, footer, contentStyle = {} }:
  {
    header: JSX.Element,
    content: JSX.Element,
    footer?: JSX.Element,
    contentStyle?: Record<string, string>
  }) {
  const contentRef = useRef<HTMLIonContentElement>(null);

  return (
    <UserLayoutContext.Provider value={{ contentRef: contentRef }}>
      <IonHeader>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol size={'12'} size-md={'8'} className='p-0 m-0'>
              {header}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>
      <IonContent forceOverscroll={false} fullscreen={true} ref={contentRef} style={contentStyle}>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol size={'12'} size-md={'8'} className='p-0 m-0'>
              {content}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      { footer && <IonFooter className={'overflow-yonly-auto'}>{footer}</IonFooter>}
    </UserLayoutContext.Provider>
  );
}

class UserLayoutErrorBoundary extends Component {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public state: { error: any } = {
    error: null
  };

  public static getDerivedStateFromError (error: Error & { cause?: Error }) {
    return { error };
  }

  componentDidCatch (error: Error, errorInfo: ErrorInfo) {
    error.stack += '\nerrorInfo.componentStack\n' + errorInfo.componentStack;
    reportException(error, 'componentDidCatch in UserErrorBoundary');
    this.setState({ error });
    return false;
  }

  public render () {
    if (this.state.error) {
      return (
        <UserLayoutPage
          header={
            <IonToolbar>
              <IonButtons slot="start">
                <IonMenuButton autoHide={true}/>
              </IonButtons>
              <IonTitle>Error</IonTitle>
              <UserDropdown slot="end"/>
            </IonToolbar>
          }
          content={
            <IonGrid>
              <IonRow class="ion-align-items-center" style={{ minHeight: '90vh' }}>
                <IonCol size={'12'} size-md={'8'} offset-md={'2'} size-xl={'6'} offset-xl={'3'}>
                  <div className={'ion-text-center text-secondary'}><ErrorIcon size={'50%'}/></div>
                  <h3 className={'ion-text-center'}>An error occurred while communicating with Sprancer.</h3>
                  <p className={'ion-text-center'}>Please check your network connection and <a href={'/'}>try again</a>.</p>
                </IonCol>
              </IonRow>
            </IonGrid>
          }
        />
      );
    }

    return this.props.children;
  }
}
