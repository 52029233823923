import React from 'react';
import { useFetcher, useResource } from 'rest-hooks';
import { ActivationResource, genSavedActivationId } from '../../models/activation';
import { ConnectionResource } from '../../models/connection';
import { constants, schemas } from 'sprancer-shared';
import { useHistory, useParams } from 'react-router';
import { Form, Formik, FormikHelpers } from 'formik';
import { SaveButton } from '../../components/FormButtons';
import { reportException } from '../../libs/errors';
import { IonFormikCheckbox, IonFormikInput, UnexpectedFormErrors } from '../../components/Forms';
import { Link } from 'react-router-dom';
import ActivationErrorBoundary from './ActivationErrorBoundary';
import { SendLoginTokenResource, UserResource } from '../../models/user';
import { ActivationBanner, ActivationWelcome } from '../../components/Activations';
import { IonCol, IonGrid, IonItem, IonLabel, IonRow, isPlatform } from '@ionic/react';
import { WelcomeFormType } from '../Auth/Welcome';
import { NoAuthLayoutPage } from '../../containers/NoAuthLayout';
import { useBackground } from '../../libs/background';
import { usePrefersDarkMode } from '../../libs/mediaQueries';
import { EmailFormLabelIcon, NameFormLabelIcon } from '../../libs/icons';
import * as yup from 'yup';

export default function Activation () {
  const { contentStyle } = useBackground();

  return (
    <ActivationErrorBoundary>
      <NoAuthLayoutPage
        header={<Header/>}
        content={<Content/>}
        contentStyle={contentStyle}
      />
    </ActivationErrorBoundary>
  );
}

function Header () {
  return (<></>);
}

function Content () {
  const { activationId } = useParams<{ activationId: string }>();
  const activation = useResource(ActivationResource.detailShape(), { id: activationId });

  let form = <ConnectForm activation={activation} />;
  if (!isPlatform('hybrid') && isPlatform('ios')) {
    form = <GetAppForm activation={activation} storeUrl={constants.SPRANCER_APP_STORE_URL}/>;
  } else if (!isPlatform('hybrid') && isPlatform('android')) {
    form = <GetAppForm activation={activation} storeUrl={constants.SPRANCER_PLAY_STORE_URL}/>;
  }

  return (
    <div className={'mb-4'}>
      <ActivationBanner />
      <IonGrid className='pb-2 px-0'>
        <IonRow className='p-0'>
          <IonCol className='p-0' size={'12'} size-md={'8'} offset-md={'2'} size-xl={'6'} offset-xl={'3'}>
            <div className={'mb-3'}>
              <ActivationWelcome activationId={activationId} />
            </div>
            { form }
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
}

const ConnectFormSchema = yup.object({
  nickname: yup.string().required().default(''),
  avatar: yup.string().notRequired(),
  email: yup.string().email().required().default(''),
  selectedGroupIds: yup.array().of(yup.string().required()).defined().default([])
});

type ConnectFormType = schemas.TokenUserCreateType & { selectedGroupIds: string[] };

function ConnectForm ({ activation }: {activation: ActivationResource}) {
  const history = useHistory<{ email?: string, formState?: WelcomeFormType['formState'], newActivation?: boolean, activationId?: string, userName?: string }>();
  const darkmode = usePrefersDarkMode();

  const createUser = useFetcher(UserResource.createTokenUserShape());
  const create = useFetcher(ConnectionResource.createForActivationShape());
  const update = useFetcher(ConnectionResource.updateShape());
  const sendLoginToken = useFetcher(SendLoginTokenResource.sendLoginTokenShape());

  async function handleSubmit (values: ConnectFormType, actions: FormikHelpers<ConnectFormType>) {
    try {
      const { selectedGroupIds, ...tokenUserCreateValues } = values;
      const user: schemas.UserType = await createUser({}, tokenUserCreateValues);
      try {
        const connection = await create({ tus: user.tokenUserSecret as string }, { activationSecretId: activation.id });
        try {
          const hiddenGroups = activation.businessProfile.groups.length <= 1
            ? []
            : activation.businessProfile.groups.filter(g => !selectedGroupIds.includes(g.id));
          await update(
            { id: connection.id, tus: user.tokenUserSecret as string },
            {
              notifications: {
                direct: 'instant',
                other: schemas.DEFAULT_FREQUENCY,
                groups: hiddenGroups.map(g => ({ id: g.id, freq: 'hide' }))
              }
            }
          );
        } catch (e) {
          // hmm couldn't update the connection.  Report and ignore.
          reportException(e, 'handleSubmit update failed in Activation UnauthConnectForm');
        }
        actions.setSubmitting(false);
        history.replace({ pathname: `/connections/${connection.id}/messages`, search: `?tus=${user.tokenUserSecret}`, state: { newActivation: true } });
      } catch (e) {
        if (e.response?.status === 403) {
          // 403 means that the business isn't allowed any more connections, send them to the Link Expired page.
          reportException(e, 'handleSubmit create failed with 403 in Activation UnauthConnectForm');
          history.push(`/activations/${activation.id}/linkexpired`, { email: values.email });
        } else {
          reportException(e, 'handleSubmit create failed in Activation UnauthConnectForm');
          actions.setStatus(e.message || e);
          actions.setSubmitting(false);
        }
      }
    } catch (e) {
      if (e.response?.status === 409) {
        // 409 means the email address is already in use by a token customer.
        try {
          const sendTokenValues: schemas.SendLoginTokenType = { email: values.email, activationId: activation.id };
          const response: schemas.SendLoginTokenResultType = await sendLoginToken({}, sendTokenValues);
          if (response.result === 'sent') {
            const tokenSentFormState: WelcomeFormType['formState'] = 'tokensent';
            history.push({ pathname: '/login/welcome', state: { email: values.email, formState: tokenSentFormState, activationId: activation.id } });
          } else {
            throw new Error(`Failed to connect ${response?.result}`);
          }
        } catch (e) {
          reportException(e, 'handleSubmit sendLoginToken failed in Activation UnauthConnectForm');
          actions.setStatus(e.message || e);
          actions.setSubmitting(false);
        }
        // history.push(`/login/password`, { email: values.email, activationId: activation.id });
      } else if (e.response?.status === 401) {
        // 401 means the email address is already in use.  Send them to the login page.
        history.push('/login/password', { email: values.email, activationId: activation.id });
      } else {
        reportException(e, 'handleSubmit createUser failed in Activation UnauthConnectForm');
        actions.setStatus(e.message || e);
        actions.setSubmitting(false);
      }
    }
  }

  const initialValues: ConnectFormType = {
    email: history.location.state?.email || '',
    nickname: history.location.state?.userName || '',
    avatar: '',
    selectedGroupIds: activation.businessProfile.groups.map(group => group.id)
  };

  return (

    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ConnectFormSchema}
      validateOnBlur={false} >
      {({ isSubmitting }) => (
        <Form>
          <UnexpectedFormErrors expectedErrors={['nickname', 'email']}/>
          <div className={'mb-3'}>
            <IonItem lines='none'>
              <IonLabel>
                <p className={'text-wrap text-muted'}>
                  Enter your name and email
                </p>
              </IonLabel>
            </IonItem>
            <IonFormikInput name='nickname' type='text' label={<NameFormLabelIcon />} autocomplete='name' autocapitalize={'words'} placeholder={'Name'} />
            <IonFormikInput name='email' type='email' label={<EmailFormLabelIcon />} autocomplete='email' placeholder='Email' />
          </div>
          { activation.businessProfile.groups.length > 1 &&
            <div className={'mb-3'}>
              <IonItem lines='none'>
                <IonLabel>
                  <p className={'text-wrap text-muted'}>
                    Choose everything that interests you
                  </p>
                </IonLabel>
              </IonItem>
              {
                activation.businessProfile.groups.map(group => {
                  return <IonItem key={group.id} lines={'full'}>
                    <IonFormikCheckbox name='selectedGroupIds' value={group.id} slot={'start'}/> {group.name}
                  </IonItem>;
                })
              }
            </div>
          }
          <IonItem className='text-wrap' lines={'none'}>
            <div className='small text-muted mb-2'>
              <p>Email is never shared or sold.  It is used for notifications and account access.</p>
              <p>Please read the <a href="https://www.sprancer.com/terms">Terms of Use</a> and <a href="https://www.sprancer.com/privacy">Privacy policy</a>.</p>
            </div>
          </IonItem>
          <IonItem lines={'none'} className={'mb-3'}>
            <IonLabel>
              <SaveButton expand={'block'} size='default' text={<>Connect</>} disabled={isSubmitting} loadingText={'Connecting…'} isLoading={isSubmitting} />
            </IonLabel>
          </IonItem>
          <div className={`text-center mb-4 p-3 ${darkmode ? 'text-muted' : 'bg-white-half-transparent'}`}>
            <Link to={'/login/welcome'}>Already connected?</Link>
          </div>
        </Form>
      )}

    </Formik>
  );
}

const GetAppFormSchema = yup.object({
  nickname: yup.string().required().default(''),
  email: yup.string().email().required().default('')
});

type GetAppFormType = {
  nickname: string;
  email: string;
}

function GetAppForm ({ activation, storeUrl }: { activation: ActivationResource, storeUrl: string }) {
  const saveActivation = useFetcher(ActivationResource.saveActivationShape());

  async function handleSubmit (values: GetAppFormType, actions: FormikHelpers<GetAppFormType>) {
    try {
      const saveActivationValues: schemas.ActivationCreateType = {
        id: genSavedActivationId(values.email),
        activationSecretId: activation.activationSecretId,
        userName: values.nickname
      };

      await saveActivation({}, saveActivationValues);
      actions.setSubmitting(false);
      window.location.replace(storeUrl);
    } catch (e) {
      reportException(e, 'handleSubmit createUser failed in Activation GetAppForm');
      actions.setStatus(e.message || e);
      actions.setSubmitting(false);
    }
  }

  const initialValues: GetAppFormType = GetAppFormSchema.required().default();

  return (

    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ConnectFormSchema}
      validateOnBlur={false} >
      {({ isSubmitting }) => (
        <Form>
          <UnexpectedFormErrors expectedErrors={['nickname', 'email']}/>
          <div className={'mb-3'}>
            <IonItem lines='none'>
              <IonLabel>
                <p className={'text-wrap text-muted'}>
                  Enter your name and email
                </p>
              </IonLabel>
            </IonItem>
            <IonFormikInput name='nickname' type='text' label={<NameFormLabelIcon />} autocomplete='name' autocapitalize={'words'} placeholder={'Name'} />
            <IonFormikInput name='email' type='email' label={<EmailFormLabelIcon />} autocomplete='email' placeholder='Email' />
          </div>
          <IonItem lines={'none'} className={'mb-3'}>
            <IonLabel>
              <SaveButton expand={'block'} size='default' text={<>Get The App</>} disabled={isSubmitting} loadingText={'Continuing…'} isLoading={isSubmitting} />
            </IonLabel>
          </IonItem>
        </Form>
      )}

    </Formik>
  );
}
