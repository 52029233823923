import { IonLabel } from '@ionic/react';
import React from 'react';

export function Logo ({ url, size = 'md' }: { url?: string, size?: 'xs' | 'sm' | 'md' | 'lg' }) {
  return (<img src={url} className={`logo-${size}`} />);
}

export function LogoWithName ({ url, name, size = 'md' }: {url?: string, name: React.ReactNode, size?: 'xs' | 'sm' | 'md' | 'lg' }) {
  return (
    <>
      {url && <img src={url} className={`logo-${size}`} /> }
      <IonLabel className={'ml-1 text-muted text-wrap'}>{name}</IonLabel>
    </>
  );
}
