import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { History } from 'history';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import * as Auth from './libs/auth';
import {
  AuthenticatedRoute,
  AuthOrUnauthRoute,
  TusableAuthenticatedRoute,
  UnauthenticatedRoute
} from './components/Auth';
import { AppContext } from './libs/context';
import NoAuthLayout from './containers/NoAuthLayout';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './App.scss';

/* Theme variables */
import './theme/variables.css';
import UserLayout from './containers/UserLayout';
import { Loading } from './components/Loading';
import AppUrlListener from './components/AppUrlListener';
import { reportException } from './libs/errors';
import { usePushNotificationSignout } from './libs/pushNotifications';
import { getStoredTus, removeStoredTus } from './libs/tokenUserAuth';
import { useFontSize } from './libs/fontSize';
import { useAppIsActive } from './libs/capacitorApp';
import { Redirect, Route, useHistory } from 'react-router';
import Logout from './views/Auth/Logout';

Object.fromEntries = Object.fromEntries || ((pairs: [key: string, value: unknown][]) => (
  Array.from(pairs).reduce(
    (acc, [key, value]) => Object.assign(acc, { [key]: value }),
    {}
  )
));

export default function App () {
  const [authenticated, setAuthenticated] = useState(false);
  const [storedTus, setStoredTus] = useState<string | undefined>(undefined);
  const [authenticating, setAuthenticating] = useState(true);

  const [fontSize, storeAndSetFontSize] = useFontSize();
  async function onLoad () {
    try {
      const [user, retrievedTus] = await Promise.all([
        Auth.currentSession(),
        getStoredTus().catch(e => { reportException(e, 'getStoredTus failed in App onLoad'); return undefined; })
      ]);
      if (user !== null) {
        setAuthenticated(true);
        setStoredTus(undefined);
      } else {
        setStoredTus(retrievedTus);
      }
    } catch (e) {
      reportException(e, 'Auth.currentSession failed in App onLoad');
    }
    setAuthenticating(false);
  }
  useEffect(() => { onLoad().catch(e => reportException(e, 'onLoad failed in App useEffect')); }, []);

  const appIsActive = useAppIsActive();

  const pushNotificationsSignout = usePushNotificationSignout();

  function signInAuthenticated () {
    setAuthenticated(true);
    setStoredTus(undefined);
  }

  async function signOut (history: History, userId?: string, tus?: string) {
    if (confirm('Are you sure you would like to logout?')) {
      if (userId) {
        await pushNotificationsSignout(userId, tus);
      }
      await Promise.all([
        Auth.signOut(),
        removeStoredTus()
      ]);
      setAuthenticated(false);
      setStoredTus(undefined);
      history.replace('/logout');
    }
  }

  if (authenticating) {
    return (<div className='animated fadeIn pt-3 text-center'>Authenticating...</div>);
  }

  return (
    <AppContext.Provider value={{ authenticated, signInAuthenticated, storedTus, setStoredTus, fontSize, appIsActive, storeAndSetFontSize, signOut }}>
      <React.Suspense fallback={<Loading />}>

        <IonApp className={fontSize ? fontSize + 'fonts' : ''}>
          <IonReactRouter>
            {/* <HistoryLogger /> */}
            <AppUrlListener />
            <Switch>
              {/* Need this temporarily since version 1.3.1 of the back end sent out activation links as /user/activations/... */}
              <Route path={'/user/activations'} component={UserActivationRedirect} />
              <Route path={'/unsubscribe'} component={NoAuthLayout} />
              <UnauthenticatedRoute path='/login' component={NoAuthLayout} />
              <Route path='/logout' exact={true} component={Logout} />
              <AuthOrUnauthRoute path='/activations' authComponent={UserLayout} unauthComponent={NoAuthLayout} />
              <AuthenticatedRoute exact path='/profileCreate' component={NoAuthLayout}/>
              <TusableAuthenticatedRoute path='/' component={UserLayout} />
            </Switch>
          </IonReactRouter>
        </IonApp>
      </React.Suspense>
    </AppContext.Provider>
  );
}

function UserActivationRedirect () {
  const history = useHistory();
  reportException(new Error('Warning UserActivationRedirect'), 'UserActivationRedirect warned in App');
  return (<Redirect to={{ ...history.location, pathname: history.location.pathname.replace(/^\/user/, '') }} />);
}

// function HistoryLogger () {
//   const history = useHistory();
//   const { storedTus } = useContext(AppContext);
//   useEffect(() => {
//     const unlisten = history.listen((location, action) => {
//       console.log('Route to a:' + action + ' l:' + JSON.stringify(location) + ' stus:' + storedTus);
//     });
//     return unlisten;
//   }, [history, storedTus]);
//   return (<></>);
// }
