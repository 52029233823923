import { UserLayoutPage } from '../../containers/UserLayout';
import {
  IonBackButton,
  IonButtons,
  IonItem,
  IonLabel, IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { useParams } from 'react-router';
import React from 'react';
import { useTokenUserSecret } from '../../libs/tokenUserAuth';
import { ConnectionResource } from '../../models/connection';
import { Logo } from '../../components/Logos';
import { CopyActivationLinkButtonForGroup, ShowActivationLinkQRCodeButtonForGroup } from '../../components/Activations';

export default function ShareConnection ({ connection }: { connection: ConnectionResource }) {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content connection={connection}/>}
    />
  );
}

function Header () {
  const { tusifyUrl } = useTokenUserSecret();
  const { connectionId } = useParams<{ connectionId: string }>();

  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton defaultHref={tusifyUrl(`/connections/${connectionId}/messages`)}/>
      </IonButtons>
      <IonTitle>Invite Others</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

function Content ({ connection }: { connection: ConnectionResource }) {
  return (<>
    <IonItem color='transparent' lines='none'>
      <h4 className={'text-muted text-break'}>Connect someone with</h4>
    </IonItem>
    <IonRow className='ion-justify-content-center my-2'>
      <Logo url={connection.businessProfile.logoImageUrl} size={'lg'}></Logo>
    </IonRow>
    <IonRow className='ion-justify-content-center'>
      <IonItem lines='none' button={true} detail={false} >
        {connection.businessFullName()}
      </IonItem>
    </IonRow>
    <IonItem color='transparent' lines='none' className={'mb-4'}>
      <IonLabel>
        <p className={'text-wrap text-muted'}>Connecting others to {connection.businessName()} helps {connection.businessContactName()}.  Support local business and grow your community!</p>
      </IonLabel>
    </IonItem>
    <IonItem lines="none">
      <IonLabel>
        <ShowActivationLinkQRCodeButtonForGroup
          business={connection.businessProfile}
          referrerType={'U'}
          referrer={connection.customerId()}
        />
      </IonLabel>
    </IonItem>
    <IonItem lines="none">
      <IonLabel>
         <CopyActivationLinkButtonForGroup
           business={connection.businessProfile}
           referrerType={'U'}
           referrer={connection.customerId()} />
      </IonLabel>
    </IonItem>
  </>);
}
