import React, { ReactNode, useState } from 'react';
import { IonPopover, IonList, IonItem, IonItemDivider, isPlatform, IonButton, IonLabel } from '@ionic/react';
import { AvatarImage } from './Avatars';
import { useGetCurrentUser } from '../models/user';
import { useAppContext } from '../libs/context';
import { useHistory } from 'react-router';
import { useTokenUserSecret } from '../libs/tokenUserAuth';
import { MoreIcon } from '../libs/icons';
import { ConnectionResource } from '../models/connection';

export function Dropdown ({ cssClass, buttonContent, children, ...props } :
  { cssClass?: string, buttonContent: ReactNode, children: ReactNode } & Parameters<typeof IonButton>[0]) {
  const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({
    open: false,
    event: undefined
  });

  function close () {
    setShowPopover({ open: false, event: undefined });
  }

  return (
    <>
      <IonPopover
        cssClass={cssClass}
        isOpen={showPopover.open}
        event={showPopover.event}
        onDidDismiss={close} >
        <IonList>
          {children}
        </IonList>
      </IonPopover>
      <IonButton {...props} onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent }) }>{buttonContent}</IonButton>
    </>
  );
}

export function UserDropdown ({ slot } : {slot: string, showUserLinks?: boolean}) {
  const { signOut } = useAppContext();
  const history = useHistory();

  const { tus, tusifyUrl } = useTokenUserSecret();

  const user = useGetCurrentUser();

  const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({
    open: false,
    event: undefined
  });

  function close () {
    setShowPopover({ open: false, event: undefined });
  }

  const popoverItems = [];
  popoverItems.push(<IonItem key={'user'} lines="none"><IonLabel><h2>Logged in as:</h2><p>{user.nickname}</p></IonLabel></IonItem>);
  popoverItems.push(<IonItemDivider key={'divider'}/>);
  popoverItems.push(<IonItem key={'profile'} lines="none" button routerLink={tusifyUrl('/user/profile')} detail>Profile</IonItem>);
  if (!isPlatform('hybrid')) { // only show the logout button on the web app.
    popoverItems.push(<IonItem key={'logout'} button detail={false} lines="none" onClick={() => signOut && signOut(history, user.userId, tus)}>Logout</IonItem>);
  }

  return (
    <>
      <IonPopover
        isOpen={showPopover.open}
        event={showPopover.event}
        onDidDismiss={close} >
        <IonList>
          {popoverItems}
        </IonList>
      </IonPopover>
      <IonItem
        lines="none"
        detail={false}
        slot={slot}
        onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent }) }
      >
        <AvatarImage avatar={user.avatar} size={'md'}/>
      </IonItem>
    </>
  );
}

export function ConnectionDropdown ({ connection, showHours }: {connection: ConnectionResource, showHours: () => void }) {
  const { tusifyUrl } = useTokenUserSecret();

  const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({
    open: false,
    event: undefined
  });

  function close () {
    setShowPopover({ open: false, event: undefined });
  }

  const popoverItems = [];
  popoverItems.push(<IonItem key={'profile'} lines="none" button onClick={close} routerLink={tusifyUrl(`/connections/${connection.id}/messages/notifications`)} detail>Edit Notifications</IonItem>);
  if (connection.businessProfile.publicActivationSecretId) {
    popoverItems.push(
      <IonItem key={'profile'} lines="none" button onClick={close} routerLink={tusifyUrl(`/connections/${connection.id}/messages/share/share`)} detail>
        Invite Others
      </IonItem>
    );
  }
  if (connection.businessProfile.websiteUrl) {
    popoverItems.push(
      <IonItem key={'profile'} lines="none" button onClick={close} href={connection.businessProfile.websiteUrl} target={'_blank'} rel={'noopener noreferrer nofollow'} detail>
        Website
      </IonItem>
    );
  }
  if (connection.businessProfile.hours) {
    popoverItems.push(
      <IonItem key={'profile'} lines="none" button onClick={() => { close(); showHours(); } } detail>
        Hours
      </IonItem>
    );
  }

  return (
    <>
      <IonPopover
        isOpen={showPopover.open}
        event={showPopover.event}
        onDidDismiss={close} >
        <IonList>
          {popoverItems}
        </IonList>
      </IonPopover>
      <IonButton
        className='ml-auto'
        color="primary"
        fill="clear"
        onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent }) }
      ><MoreIcon size={'1.5em'} /></IonButton>
    </>
  );
}
