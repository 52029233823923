import { useLocation } from 'react-router';
import { Denormalize, DenormalizeNullable, ReadShape, useResource } from 'rest-hooks';
import { Plugins } from '@capacitor/core';
import { reportException } from './errors';
import { useEffect } from 'react';
import { isPlatform } from '@ionic/react';
import { useAppContext } from './context';

const { Storage } = Plugins;
const TOKEN_USER_SECRET_STORAGE_KEY = 'TUS';

export function useTusStorage (tus: string | undefined, authenticated: boolean) {
  const { setStoredTus } = useAppContext();
  useEffect(() => {
    if (isPlatform('hybrid')) {
      if (tus && !authenticated) {
        setTus(tus).then(() => setStoredTus(tus)).catch(e => { reportException(e, 'setTus failed in tokenUserAuth useTusStorage'); });
      } else if (authenticated) {
        removeStoredTus().then(() => setStoredTus(undefined)).catch(e => { reportException(e, 'removeStoredTus failed in tokenUserAuth useTusStorage'); });
      }
    }
  }, [tus, setStoredTus, authenticated]);
}

export async function getStoredTus (): Promise<string | undefined> {
  if (!isPlatform('hybrid')) { return undefined; } // ignore if we're not on a phone

  const { value } = await Storage.get({ key: TOKEN_USER_SECRET_STORAGE_KEY });
  return value || undefined;
}

export async function removeStoredTus (): Promise<void> {
  if (!isPlatform('hybrid')) { return; } // ignore if we're not on a phone

  await Storage.remove({ key: TOKEN_USER_SECRET_STORAGE_KEY });
}

async function setTus (tus: string): Promise<void> {
  if (!isPlatform('hybrid')) { return; } // ignore if we're not on a phone

  await Storage.set({ key: TOKEN_USER_SECRET_STORAGE_KEY, value: tus });
}

export function useTokenUserSecret (): { tus: string | undefined, tusifyUrl: (url: string) => string} {
  const location = useLocation();
  const tus = new URLSearchParams(location.search).get('tus') || undefined;
  return { tus, tusifyUrl: (url: string) => (tus ? url + `?tus=${tus}` : url) };
}

export function tusifyUrl (url: string, tus: string | undefined): string {
  return tus ? url + `?tus=${tus}` : url;
}

// export function useTusableResource(fetchShape: ReadShape<any, any>, params: Record<string, unknown>) {
//   const location = useLocation();
//   const tus = new URLSearchParams(location.search).get('tus') || undefined;
//   return useResource(fetchShape, { ...params, ...tus && { tus } });
// }

type CondNull<P, A, B> = P extends null ? A : B;

type ParamsFromShape<S> = S extends {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    fetch: (first: infer A, ...rest: any) => any;
  }
  ? A
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  : S extends { getFetchKey: (first: infer A, ...rest: any) => any }
    ? A
    : never;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function useTusableResource<S extends ReadShape<any, any>,
  P extends ParamsFromShape<S> | null
  > (
  fetchShape: S,
  params: P
): CondNull<P, DenormalizeNullable<S['schema']>, Denormalize<S['schema']>> {
  const location = useLocation();
  const tus = new URLSearchParams(location.search).get('tus') || undefined;
  return useResource(fetchShape, { ...params, ...tus && { tus } });
}
