import React from 'react';
import { UserLayoutPage } from '../../containers/UserLayout';
import { IonButtons, IonTitle, IonToolbar, IonBackButton } from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import RedeemCodeForm from './RedeemCodeForm';
import { useTokenUserSecret } from '../../libs/tokenUserAuth';

export default function UserRedeemCode () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
    />
  );
}

function Header () {
  const { tusifyUrl } = useTokenUserSecret();

  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton defaultHref={tusifyUrl('/user/home')}/>
      </IonButtons>
      <IonTitle>Redeem Code</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

function Content () {
  return (<div className={'px-3'}>
    <h3 className={'text-muted text-center mb-4'}>Enter your code:</h3>
    <div className={'mb-4'}>
      <RedeemCodeForm />
    </div>
  </div>);
}
